import React from 'react'
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { RouteComponentProps, navigate } from '@reach/router';
import { connect } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import dayjs from '../../libs/dayjs';
import Form from '../../components/ClientsDatabase/Form/Form';
import { CreateClientFormData } from '../../redux/modules/clients/clients.interfaces';
import { createClient, CreateClient } from '../../redux/modules/clients/clients.thunks';
import { RootState } from '../../redux';

const defaultValues = {
  firstName: '',
  lastName: '',
  phone: '+48',
  date: dayjs().format('YYYY-MM-DD'),
  notes: '',
  goodForPhotos: false,
  fromPhoto: false,
  photoTaken: false,
  pinned: false,
}

interface CreateClientPageReduxProps {
  isLoading: boolean,
  createClient: CreateClient,
}

const CreateClientPage: React.FunctionComponent<RouteComponentProps & WithSnackbarProps & CreateClientPageReduxProps> = ({
  enqueueSnackbar, createClient, isLoading, 
}) => {
  const submitForm = async (values: CreateClientFormData) => {
    try {
      await createClient({
        ...values,
        date: dayjs(values.date).toString(),
      });
      enqueueSnackbar('Klient został dodany', { variant: 'success'});
      navigate('/client');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  };

  return (
    <Layout>
      <Form
        isLoading={isLoading}
        onFormSubmit={submitForm}
        onFormCancel={() => navigate('/')}
        formSubmitButtonText="Dodaj"
        {...defaultValues}
      />
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.clients.isLoading, 
})

const mapDispatchToProps = {
  createClient,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(CreateClientPage));
