import React from 'react'
import { styled } from '@mui/material/styles';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { RouteComponentProps, navigate } from '@reach/router';
import { CircularProgress } from '@mui/material';
import Layout from '../../components/Layout/Layout';
import dayjs from '../../libs/dayjs';
import Form from '../../components/ReserveList/Form';
import { RootState } from '../../redux';
import {connect, useDispatch} from 'react-redux';
import {ReserveListData, UpdateReserveListDTO} from "../../redux/modules/reserveList/reserveList.interfaces";
import {findOneReserveList, updateReserveList} from "../../redux/modules/reserveList/reserveList.thunks";

const PREFIX = 'Update';

const classes = {
  loaderContainer: `${PREFIX}-loaderContainer`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  }
}));

interface Props {
  id: string,
}

interface UpdatePageReduxProps {
  isLoading: boolean,
  reserveListEntry: ReserveListData,
}

const UpdatePage: React.FunctionComponent<RouteComponentProps & Props & UpdatePageReduxProps & WithSnackbarProps> = ({
  id, enqueueSnackbar, isLoading, reserveListEntry
}) => {
  const dispatch = useDispatch();
  const submitForm = async (values: UpdateReserveListDTO) => {
    try {
      dispatch(updateReserveList(id, values));
      enqueueSnackbar('Wpis na liste rezerwową został zaktualizowany',{
        variant: 'success',
      });
      navigate('/reserve-list');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  };

  React.useEffect(() => {
    dispatch(findOneReserveList((id)));
  }, [id]);

  return (
    <StyledLayout>
      { isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}

      { reserveListEntry._id && (
        <Form
          isLoading={isLoading}
          onFormSubmit={submitForm}
          onFormCancel={() => navigate(`/makeup/${id}`)}
          formSubmitButtonText="Zaktualizuj"
          {...reserveListEntry}
          peopleCount={reserveListEntry.peopleCount.toString()}
          date={dayjs(reserveListEntry.date).format('YYYY-MM-DD')}
          time={reserveListEntry.time}
        />
      )}

    </StyledLayout>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.reserveList.isLoading,
  reserveListEntry: state.reserveList.reserveListEntry,
})

export default connect(
  mapStateToProps,
)(withSnackbar(UpdatePage));
