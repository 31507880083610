import React from 'react';
import { CardContent, Typography, Icon } from '@mui/material';
import { ListItemContainer, ListItemRow, ListItemContent, ListItemActions, ListItemAction, Place, CameraIcon } from '../../ListItem/ListItem.styled';
import { Link } from '@reach/router';
import { BlacklistData } from '../../../redux/modules/blacklists/blacklists.interfaces';
import {useDispatch} from "react-redux";
import { setRewindId } from '../../../redux/modules/base';
import dayjs from "../../../libs/dayjs";

interface Props {
  details?: boolean,
  fromArchive?: boolean,
}

const ListItem: React.FunctionComponent<BlacklistData & Props> = ({
  firstName, lastName, phone, source, notes, _id, details, ...blacklistEntry
}) => {
  const dispatch = useDispatch();

  const parsedDate = dayjs(blacklistEntry.createdAt).format('MMMM YYYY');

  return (
    <Link to={`/blacklist/${_id}`} onClick={() => dispatch(setRewindId({ rewindId: _id }))}>
      <ListItemContainer id={_id} details={details}>
        <span id={`${_id}-anchor`} style={{ position: 'absolute', top: '-150px'}}></span>
        <CardContent>
          <ListItemRow>
            <ListItemContent>
              <Place variant="subtitle1">
                <Typography variant="subtitle2" style={{ textTransform: 'capitalize' }}>{parsedDate}</Typography>
              </Place>
              <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
            </ListItemContent>
            <ListItemActions>
              <ListItemAction href={`tel:${phone}`} onClick={(e) => e.stopPropagation()}>
                <Icon>phone</Icon>
              </ListItemAction>
              <ListItemAction href={`sms:${phone}`} onClick={(e) => e.stopPropagation()}>
                <Icon>message</Icon>
              </ListItemAction>
            </ListItemActions>
          </ListItemRow>
        </CardContent>
      </ListItemContainer>
    </Link>
  )
}

export default ListItem;
