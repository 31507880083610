import axios, { AxiosResponse } from "axios";
import { CreateBlacklistFormData, UpdateBlacklistDTO, BlacklistDataDTO, BlacklistData } from "./blacklists.interfaces";
import { setAll, setOne, setIsLoading } from ".";
import { RootState } from "../..";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

export const findAllBlacklists = ():ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  const response: AxiosResponse<BlacklistDataDTO> = await axios.get(`${process.env.REACT_APP_API_URL}/blacklist/`);

  dispatch(setAll(response.data));
  dispatch(setIsLoading({ isLoading: false }));
}

export type FindOneBlacklist = (id: string) => Promise<void>;

export const findOneBlacklist = (id: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  const response: AxiosResponse<BlacklistData> = await axios.get(`${process.env.REACT_APP_API_URL}/blacklist/${id}`);

  dispatch(setOne(response.data));
  dispatch(setIsLoading({ isLoading: false }));
}

export type CreateBlacklist = (data: CreateBlacklistFormData) => Promise<void>;

export const createBlacklist = (data: CreateBlacklistFormData):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/blacklist`, data);
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export type UpdateBlacklist = (id: string, data: UpdateBlacklistDTO) => Promise<void>;

export const updateBlacklist = (id: string, data: UpdateBlacklistDTO):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await axios.put(`${process.env.REACT_APP_API_URL}/blacklist/${id}`, data);
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export type DeleteBlacklist = (id: string) => Promise<void>;

export const deleteBlacklist = (id: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/blacklist/${id}`);
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}
