import { createSlice } from "redux-starter-kit";
import state from "./clients.state";
import {
  setAllReducer,
  setOneReducer,
  setIsLoadingReducer,
  setClientToEditReducer,
  setSearchClientsReducer, setQuerySelector
} from "./clients.reducers";

const clientsSlice = createSlice({
  slice: 'clients',

  initialState: state,

  reducers: {
    setAll: setAllReducer,
    setSearchClients: setSearchClientsReducer,
    setOne: setOneReducer,
    setClientToEdit: setClientToEditReducer,
    setIsLoading: setIsLoadingReducer,
    setQuery: setQuerySelector,
  }
})
// Extract the action creators object and the reducer
const { actions, reducer, selectors } = clientsSlice
// Extract and export each action creator by name
export const { setAll, setOne, setClientToEdit, setIsLoading, setSearchClients, setQuery } = actions

export const { getClients } = selectors;
// Export the reducer, either as a default or named export
export default reducer
