import React from 'react'
import {withSnackbar, WithSnackbarProps} from 'notistack';
import {navigate, RouteComponentProps} from '@reach/router';
import Layout from '../../components/Layout/Layout';
import dayjs from '../../libs/dayjs';
import Form from '../../components/ReserveList/Form';
import {connect, useDispatch} from 'react-redux';
import {RootState} from '../../redux';
import {createReserveList} from "../../redux/modules/reserveList/reserveList.thunks";
import {CreateReserveListFormData} from "../../redux/modules/reserveList/reserveList.interfaces";

const defaultValues = {
  type: 'RESERVE_LIST',
  firstName: 'Lista',
  lastName: 'Rezerwowa',
  localization: 'Poznań',
  source: 'Olx',
  phone: '+48',
  date: dayjs().format('YYYY-MM-DD'),
  time: '10:00',
  notes: '',
  peopleCount: '1',
  isSecondTermSent: false,
  isConfirmed: false,
  instagram: '',
}

interface CreatePageReduxProps {
  isLoading: boolean,
}

const ReserveCreatePage: React.FunctionComponent<RouteComponentProps & WithSnackbarProps & CreatePageReduxProps> = ({
  enqueueSnackbar, isLoading, location,
}) => {
  const dispatch = useDispatch();
  const submitForm = async (values: CreateReserveListFormData) => {
    try {
      await dispatch(createReserveList(values));

      enqueueSnackbar('Rezerwacja została zapisana',{
        variant: 'success',
      });

      navigate('/reserve-list');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  };

  return (
    <Layout>
      <Form
        isLoading={isLoading}
        onFormSubmit={submitForm}
        onFormCancel={() => navigate('/reserve-list')}
        formSubmitButtonText="Dodaj"
        {...defaultValues}
        type="RESERVE_LIST"
      />
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.makeups.isLoading,
})

const mapDispatchToProps = {
  createReserveList,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(ReserveCreatePage));
