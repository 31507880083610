import React, {CSSProperties} from 'react';
import dayjs from '../../libs/dayjs';
import {CardContent, Icon, Typography, Chip, Stack} from '@mui/material';
import {
  CameraIcon, CardFooter, Category,
  ListItemAction,
  ListItemActions,
  ListItemContainer,
  ListItemContent,
  ListItemRow,
  PeopleCount,
  Place
} from './ListItem.styled';
import { lightGreen, red } from '@mui/material/colors';
import {Link} from '@reach/router';
import {MakeupData, MakeupType} from '../../redux/modules/makeups/makeups.interfaces';
import {setRewindId} from "../../redux/modules/base";
import {useDispatch} from "react-redux";
import makeupLesson from '../../assets/makeup-lesson.svg';
import trialMakeup from '../../assets/trial-makeup.svg';
import fullBride from '../../assets/full_bride.png';
import groom from '../../assets/groom.svg';
import booking from '../../assets/booking.svg';

interface Props {
  details?: boolean,
  fromArchive?: boolean,
  style?: CSSProperties;
}

const ListItem: React.FunctionComponent<Props & MakeupData> = ({
  details, fromArchive, style, ...makeup
}) => {
  const dispatch = useDispatch();
  const parsedDate = dayjs(makeup.date).format('DD MMMM YYYY');
  const parsedTime = makeup.time;

  const makeupCategory = React.useMemo(() => {
    switch (makeup.type) {
      case MakeupType.BREAK:
        return 'Przerwa';
      case MakeupType.MODEL:
        return 'Modelka';
      case MakeupType.LESSON:
        return 'Lekcja makijażu';
      case MakeupType.MAKEUP:
        return 'Makijaż';
      case MakeupType.RESERVATION:
        return 'Rezerwacja terminu';
      case MakeupType.TRIAL_WEDDING:
        return 'Makijaż próbny ślubny';
      case MakeupType.GROOM:
        return 'Poprawka pana młodego';
      case MakeupType.WEDDING:
        return 'Makijaż ślubny';
      case MakeupType.RESERVE_LIST:
        return 'Wpis na liste rezerwową';
    }
  }, [makeup.type]);

  const wrapWithLink = (children: JSX.Element) => {
    if (details) {
      return children;
    }

    return (
      <Link to={`/makeup/${makeup._id}`} state={{ fromArchive }} onClick={() => dispatch(setRewindId({ rewindId: makeup._id }))} style={style}>
        {children}
      </Link>
    )
  }

  if (makeup.type === MakeupType.BREAK) {
    return (
      <Link to={`/makeup/${makeup._id}`} state={{ fromArchive }} onClick={() => dispatch(setRewindId({ rewindId: makeup._id }))}>
        <ListItemContainer break id={makeup._id} details={details} style={style}>
          <span id={`${makeup._id}-anchor`} style={{ position: 'absolute', top: '-150px'}}></span>
          <CardContent>
            <ListItemRow>
              <ListItemContent>
                <Place variant="subtitle1">
                  <Typography variant="subtitle2">{parsedDate}{' '}<strong>{parsedTime}</strong></Typography>
                  { makeup.notes.length > 0 && <CameraIcon><span role="img" aria-label="emoji">📝</span></CameraIcon>}
                </Place>
                <Typography variant="body1">{makeup.firstName}</Typography>
              </ListItemContent>
            </ListItemRow>
          </CardContent>
        </ListItemContainer>
      </Link>
    )
  }

  if (makeup.type === MakeupType.MODEL) {
    return (
      <Link to={`/makeup/${makeup._id}`} state={{ fromArchive }} onClick={() => dispatch(setRewindId({ rewindId: makeup._id }))}>
        <ListItemContainer model id={makeup._id} details={details}>
          <span id={`${makeup._id}-anchor`} style={{ position: 'absolute', top: '-150px'}}></span>
          <CardContent>
            <ListItemRow>
              <ListItemContent>
                <Place variant="subtitle1">
                  <Typography variant="subtitle2">{parsedDate}{' '}<strong>{parsedTime}</strong></Typography>
                  { makeup.notes.length > 0 && <CameraIcon><span role="img" aria-label="emoji">📝</span></CameraIcon>}
                </Place>
                <Typography variant="body1">{makeup.firstName}</Typography>
              </ListItemContent>
            </ListItemRow>
          </CardContent>
        </ListItemContainer>
      </Link>
    )
  }

  return wrapWithLink(
      <ListItemContainer reservation={makeup.type === MakeupType.RESERVATION} id={makeup._id} details={details}>
        <span id={`${makeup._id}-anchor`} style={{ position: 'absolute', top: '-150px'}}></span>
        <CardContent>
          <ListItemRow>
            <ListItemContent>
              <Category>{makeupCategory}</Category>
              <Typography variant="subtitle2">{parsedDate}{' '}<strong>{parsedTime}</strong></Typography>
              <Place variant="subtitle1">
                <Icon style={{ marginRight: '8px' }}>location_city</Icon>
                { makeup.localization }
                { makeup.source.toLowerCase() === 'foto' && <CameraIcon><span role="img" aria-label="emoji">📷</span></CameraIcon>}
                { makeup.type === MakeupType.LESSON && <CameraIcon><img src={makeupLesson} alt="Lekcja" style={{ width: 24, height: 24 }} /></CameraIcon>}
                { makeup.type === MakeupType.TRIAL_WEDDING && <CameraIcon><img src={trialMakeup} alt="Makijaż próbny ślubny" style={{ width: 24, height: 24 }} /></CameraIcon>}
                { makeup.type === MakeupType.WEDDING && <CameraIcon><img src={fullBride} alt="Makijaż ślubny" style={{ width: 24, height: 24 }} /></CameraIcon>}
                { makeup.type === MakeupType.GROOM && <CameraIcon><img src={groom} alt="Poprawka pana młodego" style={{ width: 24, height: 24 }} /></CameraIcon>}
                { makeup.type === MakeupType.RESERVATION && <CameraIcon><img src={booking} alt="Rezerwacja" style={{ width: 24, height: 24 }} /></CameraIcon>}
                { makeup.notes.length > 0 && <CameraIcon><span role="img" aria-label="emoji">📝</span></CameraIcon>}
                { makeup.peopleCount > 1 && <PeopleCount>{ makeup.peopleCount }</PeopleCount> }
              </Place>
              <Typography variant="body1">{`${makeup.firstName} ${makeup.lastName}`}</Typography>
            </ListItemContent>
            <ListItemActions>
              <ListItemAction href={`tel:${makeup.phone}`} onClick={(e) => e.stopPropagation()}>
                <Icon>phone</Icon>
              </ListItemAction>
              <ListItemAction href={`sms:${makeup.phone}`} onClick={(e) => e.stopPropagation()}>
                <Icon>message</Icon>
              </ListItemAction>
            </ListItemActions>
          </ListItemRow>
        </CardContent>
        <CardFooter details={details}>
          <Stack direction="row" spacing={1}>
            <div>
              {makeup.tags?.map(tag => (
                  <Chip color="primary" label={tag} />
              ))}
            </div>
          </Stack>
          <Typography variant="subtitle2" style={{ display: 'flex', alignItems: 'center' }}>
            Potwierdzono:
            {makeup.isConfirmed ? (
                <Icon style={{ color: lightGreen['500'] }}>check</Icon>
            ): (
                <Icon style={{ color: red['600'] }}>clear</Icon>
            )}
          </Typography>
        </CardFooter>
      </ListItemContainer>
  )
}

export default ListItem;
