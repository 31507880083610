import dayjs from "dayjs";
import 'dayjs/locale/pl';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(customParseFormat)
dayjs.locale('pl');
dayjs.extend(utc)
dayjs.extend(timezone)

export default dayjs;
