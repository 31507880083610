import { ClientsState, ClientData, GroupedClientData, ClientDataDTO } from "./clients.interfaces";
import { PayloadAction } from "redux-starter-kit";
import { IsLoadingPayload } from "../../redux.interfaces";

export const setAllReducer = (state: ClientsState, action: PayloadAction<ClientDataDTO>) => {
  state.clients = action.payload.data;
  state.clientsCount = action.payload.count;
}

export const setSearchClientsReducer = (state: ClientsState, action: PayloadAction<GroupedClientData[]>) => {
  state.searchClients = action.payload;
}

export const setOneReducer = (state: ClientsState, action: PayloadAction<GroupedClientData>) => {
  state.client = action.payload;
}

export const setClientToEditReducer = (state: ClientsState, action: PayloadAction<ClientData>) => {
  state.clientToEdit = action.payload;
}

export const setIsLoadingReducer = (state: ClientsState, action: PayloadAction<IsLoadingPayload>) => {
  state.isLoading = action.payload.isLoading;
}
export const setQuerySelector = (state: ClientsState, action: PayloadAction<string>) => {
  state.query = action.payload;
}