import React, { useEffect } from 'react'
import {RouteComponentProps, navigate, Link} from '@reach/router';
import Layout from '../../components/Layout/Layout';
import { CircularProgress, List, Fab, Icon, Input, Typography } from '@mui/material';
import ListItem from '../../components/ClientsDatabase/ListItem/ListItem';
import { ClientData, GroupedClientData } from '../../redux/modules/clients/clients.interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {getClients, setQuery} from '../../redux/modules/clients';
import { findAllClients } from '../../redux/modules/clients/clients.thunks';
import { alpha, styled } from '@mui/material/styles';
import {getBase} from "../../redux/modules/base";

const PREFIX = 'ListPage';

const classes = {
  root: `${PREFIX}-root`,
  ul: `${PREFIX}-ul`,
  subheader: `${PREFIX}-subheader`,
  fab: `${PREFIX}-fab`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.ul}`]: {
    padding: 0,
  },

  [`& .${classes.subheader}`]: {
    color: '#000',
    fontWeight: 'bold',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },

  [`& .${classes.fab}`]: {
    position: 'fixed',
    right: '16px',
    bottom: '16px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    zIndex: 1000,
  },

  [`& .${classes.search}`]: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },

  [`& .${classes.searchIcon}`]: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
    width: '100%',
  },

  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  }
}));

export interface ClientGroups {
  date: string,
  clients: ClientData[],
}

const ListPage: React.FunctionComponent<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { isLoading, clients, clientsCount, query } = useSelector(getClients);
  const { rewindId } = useSelector(getBase);

  useEffect(() => {
    dispatch(findAllClients())
  }, [dispatch]);

  useEffect(() => {
    const shouldRewind = () => {
      setTimeout(() => {
        if (rewindId) {
          const element = document.getElementById(`${rewindId}-anchor`);
          if (element) {
            element.scrollIntoView();
          }
        }
      }, 100);
    };

    shouldRewind();
  }, [rewindId]);

  const filteredClients = React.useMemo<GroupedClientData[]>(() => {
    if (!query) {
      return clients;
    }

    return clients.filter(client =>       
      `${client.firstName.toLowerCase()} ${client.lastName.toLowerCase()}`.includes(query.toLowerCase())
      || client.phone.replace(/ /g, '').includes(query));
  }, [query, clients]);

  return (
    <StyledLayout>
      { isLoading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <Icon>search</Icon>
            </div>
            <Input
              value={query}
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => dispatch(setQuery(e.target.value))}
            />
          </div>
          <List subheader={<li />} className={classes.root}>
            {filteredClients.map(clientEl => {
              let notes = '';
              const visitWithNotes = clientEl.values.find(item => !!item.notes);

              if (visitWithNotes) {
                notes = visitWithNotes.notes;
              }

              return (
                <Link to={`/client/${clientEl.phone}`}>
                  <ListItem
                    key={clientEl.phone}
                    {...clientEl}
                    notes={notes}
                  />
                </Link>
              )}
            )}
          </List>
          <Typography variant="subtitle2">
            Ilość klientów: {clientsCount}
          </Typography>
        </React.Fragment>
      )}
      <Fab className={classes.fab} onClick={() => navigate('/client/create')}>
        <Icon>add</Icon>
      </Fab>
    </StyledLayout>
  );
}

export default ListPage
