import React from 'react';
import MaskedInput from "react-text-mask";

const PhoneInput = React.forwardRef((props, ref) => {
    // Force changing value as mui textfiled can't recognize filled state
    React.useEffect(() => {
        (props as any).onChange({
            target: {
                value: ''
            }
        });

        setTimeout(() => {
            (props as any).onChange({
                target: {
                    value: (props as any).value
                }});
        }, 60);
    }, []);

    return (
        <MaskedInput
            {...props}
            ref={ref as any}
            mask={['+', /[1-9]/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            showMask
        />
    );
});

export default PhoneInput;
