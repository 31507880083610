import { PayloadAction } from "redux-starter-kit";
import { IsLoadingPayload } from "../../redux.interfaces";
import dayjs from '../../../libs/dayjs';
import {ReserveListData, ReserveListDataDTO, ReserveListState} from "./reserveList.interfaces";

export const setAllReducer = (state: ReserveListState, action: PayloadAction<ReserveListDataDTO>) => {
  state.reserveListData = action.payload.data;
}

export const setOneReducer = (state: ReserveListState, action: PayloadAction<ReserveListData>) => {
  state.reserveListEntry = action.payload;
}

export const addNewReserveListReducer = (
  state: ReserveListState, action: PayloadAction<ReserveListData>
) => {
  const makeupToAdd = action.payload;
  const makeupDate = dayjs(makeupToAdd.date);
  const makeupTime = dayjs(makeupToAdd.time);

  const idx = state.reserveListData.findIndex((reserveList) => {
    const date = dayjs(reserveList.date);
    const time = dayjs(reserveList.time);

    return (date.isSame(makeupDate) && makeupTime.isBefore(time)) || makeupDate.isBefore(date);
  });

  if (idx === -1) {
    state.reserveListData.push(makeupToAdd);
    return;
  }

  if (idx === 0) {
    state.reserveListData.unshift(makeupToAdd);
    return;
  }

  state.reserveListData.splice(idx, 0, makeupToAdd);
}

export const updateReducer = (state: ReserveListState, action: PayloadAction<ReserveListData>) => {
  if (state.reserveListEntry._id === action.payload._id) {
    state.reserveListEntry = action.payload;
  }
  const idx = state.reserveListData.findIndex(makeup => makeup._id === action.payload._id);
  state.reserveListData[idx] = action.payload;
}

export const removeReducer = (state: ReserveListState, action: PayloadAction<string>) => {
  state.reserveListData = state.reserveListData.filter(makeup => makeup._id !== action.payload);
}

export const setIsLoadingReducer = (state: ReserveListState, action: PayloadAction<IsLoadingPayload>) => {
  state.isLoading = action.payload.isLoading;
}
