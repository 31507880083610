import React, { useState, useEffect, useCallback } from 'react'
import { RouteComponentProps, navigate } from '@reach/router';
import Layout from '../components/Layout/Layout';
import {
  CircularProgress,
  List,
  Fab,
  Icon,
  Button,
  Typography,
  Select,
  InputLabel,
  FormControl,
  TextField,
  InputAdornment,
} from '@mui/material';
import ListItem from '../components/ListItem/ListItem';
import { useDispatch, useSelector } from 'react-redux';
import { getMakeups } from '../redux/modules/makeups';
import { findAllMakeups } from '../redux/modules/makeups/makeups.thunks';
import {MakeupData, FindAllParams, MakeupType} from '../redux/modules/makeups/makeups.interfaces';
import { alpha } from '@mui/material/styles';
import {getBase, setArchiveFilters} from "../redux/modules/base";
import dayjs from "dayjs";
import {ArchiveFilters} from "../redux/modules/base/base.interface";
import styled from "styled-components";
import { FixedSizeList as ListVirtualized } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

export interface MakeupGroups {
  date: string,
  makeups: MakeupData[],
}

const PREFIX = 'ARCHIVE';
const classes = {
  root: `${PREFIX}-root`,
  ul: `${PREFIX}-ul`,
  subheader: `${PREFIX}-subheader`,
  fab: `${PREFIX}-fab`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  filtersRoot: `${PREFIX}-filtersRoot`,
};

// @ts-ignore
const StyledLayout = styled(Layout)((
    {
      theme
    }
) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },
  [`& .${classes.ul}`]: {
    padding: 0,
  },
  [`& .${classes.subheader}`]: {
    color: '#000',
    fontWeight: 'bold',
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
  [`& .${classes.fab}`]: {
    position: 'fixed',
    right: '16px',
    bottom: '16px',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    zIndex: 1000,
  },
  [`& .${classes.search}`]: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  [`& .${classes.searchIcon}`]: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
    width: '100%',
  },
  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  [`& .${classes.filtersRoot}`]: {
    width: '100%',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  }
}));

const ArchivePage: React.FunctionComponent<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const { isLoading, makeups } = useSelector(getMakeups);
  const { rewindId, archiveFilters } = useSelector(getBase);
  const { dateFrom, dateTo, type: typeFilter, query } = archiveFilters;

  const [showDeleted, setShowDeleted] = useState(false);
  const [showFilters, setShowFilters] = React.useState(false);

  const fetchData = useCallback(async (params: FindAllParams) => {
    dispatch(findAllMakeups(params))
  }, [dispatch]);

  const handleFiltersChange = (key: keyof ArchiveFilters, value: any) => {
    dispatch(setArchiveFilters({
      ...archiveFilters,
      [key]: value,
    }))
  }

  const handleFiltersReset = () => {
    dispatch(setArchiveFilters({
      type: '',
      dateFrom: '',
      dateTo: '',
      query: '',
    }))
  }

  useEffect(() => {
    fetchData({ archived: true });
  }, [fetchData]);

  useEffect(() => {
    const shouldRewind = () => {
      setTimeout(() => {
        if (rewindId) {
          const element = document.getElementById(`${rewindId}-anchor`);
          if (element) {
            element.scrollIntoView();
          }
        }
      }, 100);
    };

    shouldRewind();
  }, [rewindId]);

  const filteredMakeups = React.useMemo<MakeupData[]>(() => {
    let filtered = makeups;

    if (typeFilter) {
      filtered = makeups.filter(client => client.type === typeFilter);
    } else {
      filtered = makeups.filter(client => ![MakeupType.BREAK, MakeupType.MODEL, MakeupType.RESERVATION].includes(client.type));
    }

    if (dateFrom) {
      filtered = filtered.filter(client => {
        const makeupDate = dayjs(client.date);
        const filterDate = dayjs(dateFrom).subtract(3, 'hour');
        return makeupDate.isAfter(filterDate);
      })
    }

    if (dateTo) {
      filtered = filtered.filter(client => {
        const makeupDate = dayjs(client.date);
        const filterDate = dayjs(dateTo).add(3, 'hour');
        return makeupDate.isBefore(filterDate);
      })
    }

    if (!query) {
      return filtered;
    }

    return filtered.filter(client =>       
      `${client.firstName.toLowerCase()} ${client.lastName.toLowerCase()}`.includes(query.toLowerCase())
      || client.phone.replace(/ /g, '').includes(query));
  }, [query, makeups, dateFrom, dateTo, typeFilter]);

  const count = {
    makeups: filteredMakeups.filter(makeup => ![MakeupType.LESSON, MakeupType.RESERVATION, MakeupType.MODEL, MakeupType.BREAK].includes(makeup.type))
      .reduce((sum, makeup) => sum += makeup.peopleCount, 0),
    lessons: filteredMakeups.filter(makeup => makeup.type === MakeupType.LESSON).length,
  }

  return (
    <StyledLayout>
      { isLoading ? (
        <CircularProgress />
      ) : (
        <React.Fragment>
          <div className={classes.filtersRoot}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const newShowDeleted = !showDeleted;
                setShowDeleted(newShowDeleted);
                if (newShowDeleted) {
                  fetchData({
                    deleted: true,
                  });
                } else {
                  fetchData({
                    archived: true,
                  });
                }
              }}
            >
              { showDeleted ? 'Pokaż zarchiwizowane' : 'Pokaż usunięte'}
            </Button>
            <FormControl>
              <div className={classes.search}>
                <TextField
                  value={query}
                  placeholder="Szukaj…"
                  label="Wyszukaj"
                  classes={{
                    root: classes.inputRoot,
                    // input: classes.inputInput,
                  }}
                  InputProps={{
                    'aria-label': 'search',
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon>search</Icon>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => handleFiltersChange('query', e.target.value)}
                />
              </div>
            </FormControl>
            {window.innerWidth < 768 && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowFilters(!showFilters)}
              >
                { showFilters ? 'Ukryj filtry' : 'Pokaż filtry'}
              </Button>
            )}
            { (showFilters || window.innerWidth >= 768) && (
              <>
                <FormControl>
                  <InputLabel shrink id="type">
                    Typ makijażu
                  </InputLabel>
                  <Select
                    native
                    value={typeFilter}
                    onChange={(e) => handleFiltersChange('type', e.target.value as MakeupType)}
                    inputProps={{
                      name: 'type',
                      id: 'type',
                    }}
                  >
                    <option value="">-</option>
                    <option value={MakeupType.MAKEUP}>Makijaż</option>
                    <option value={MakeupType.WEDDING}>Makijaż ślubny</option>
                    <option value={MakeupType.TRIAL_WEDDING}>Makijaż próbny ślubny</option>
                    <option value={MakeupType.RESERVATION}>Rezerwacja terminu</option>
                    <option value={MakeupType.GROOM}>Poprawka pana młodego</option>
                    <option value={MakeupType.LESSON}>Lekcja makijażu</option>
                    <option value={MakeupType.BREAK}>Zajęty dzień</option>
                    <option value={MakeupType.MODEL}>Modelka</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <TextField
                    id="date-to"
                    label="Data do"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={dateTo}
                    onChange={(e) => handleFiltersChange('dateTo', e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    id="date-from"
                    label="Data od"
                    type="date"
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={dateFrom}
                    onChange={(e) => handleFiltersChange('dateFrom', e.target.value)}
                  />
                </FormControl>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFiltersReset}
                    style={{ margin: '8px 4px' }}
                >
                  Resetuj
                </Button>
              </>
            )}
          </div>
          <Typography variant="subtitle2">
            Ilość makijaży: {count.makeups}
            <br />
            Ilość lekcji makijażu: {count.lessons}
          </Typography>
          <div style={{ flexGrow: 1, minHeight: '75vh' }}>
            <AutoSizer>
              {({ height, width }) => (
                  <ListVirtualized
                      height={height}
                      itemCount={filteredMakeups.length}
                      itemSize={196}
                      width={width}
                  >
                    {({ index, style }) => {
                      const makeupEl = filteredMakeups[index];

                      return (
                          <ListItem
                              key={makeupEl._id}
                              fromArchive
                              style={style}
                              {...makeupEl}
                          />
                      )
                    }}
                  </ListVirtualized>
              )}
            </AutoSizer>
          </div>
        </React.Fragment>
      )}
      <Fab className={classes.fab} onClick={() => navigate('/create', { state: { fromArchive: true }})}>
        <Icon>add</Icon>
      </Fab>
    </StyledLayout>
  )
}

export default ArchivePage
