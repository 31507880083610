import axios, { AxiosResponse } from "axios";
import { CreateClientFormData, UpdateClientDTO, GroupedClientData, ClientDataDTO } from "./clients.interfaces";
import {setAll, setOne, setIsLoading, setSearchClients} from ".";
import { RootState } from "../..";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

export const findAllClients = ():ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  const response: AxiosResponse<ClientDataDTO> = await axios.get(`${process.env.REACT_APP_API_URL}/client/`);

  dispatch(setAll(response.data));
  dispatch(setIsLoading({ isLoading: false }));
}

export const findSearchClients = ():ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  const response: AxiosResponse<GroupedClientData[]> = await axios.get(`${process.env.REACT_APP_API_URL}/client/search`);

  dispatch(setSearchClients(response.data));
  dispatch(setIsLoading({ isLoading: false }));
}

export type FindOneClient = (id: string) => Promise<void>;

export const findOneClient = (id: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  await axios.get(`${process.env.REACT_APP_API_URL}/client/${id}`);
}

interface GetClientOptions {
  force?: boolean,
}

export type GetClientDetails = (phone: string, options?: GetClientOptions) => Promise<void>;

export const getClientDetails = (phone: string, options: GetClientOptions = {}):ThunkAction<Promise<void>, RootState, null, AnyAction> => async (dispatch, getState) => {
  const { clients } = getState();
  const client = clients.clients.find(item => item.phone === phone);

  if (client && !options.force) {
    dispatch(setOne(client));
    return;
  }

  const response: AxiosResponse<GroupedClientData> = await axios.post(`${process.env.REACT_APP_API_URL}/client/single`, {
    phone,
  });

  dispatch(setOne(response.data));
}

export type CreateClient = (data: CreateClientFormData) => Promise<void>;

export const createClient = (data: CreateClientFormData):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/client`, data);
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export type UpdateClient = (id: string, data: UpdateClientDTO) => Promise<void>;

export const updateClient = (id: string, data: UpdateClientDTO):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await axios.put(`${process.env.REACT_APP_API_URL}/client/${id}`, data);
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export type DeleteClient = (id: string) => Promise<void>;

export const deleteClient = (id: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/client/${id}`);
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}
