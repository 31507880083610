import {BaseState} from "./base.interface";

const state: BaseState = {
  rewindId: '',
  archiveFilters: {
    type: '',
    dateFrom: '',
    dateTo: '',
    query: '',
  }
};

export default state;
