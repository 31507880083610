import React from "react";

const MakeupListIcon: React.FC<{className: string}> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path
      d="M261.206 451.029h-64.417c-73.388 0-132.88-59.492-132.88-132.88V165.088C63.909 73.913 137.821 0 228.997 0c91.176 0 165.088 73.913 165.088 165.088v153.061c.001 73.388-59.492 132.88-132.879 132.88z"
      fill="#606572"
    />
    <path
      d="M228.997 0c-4.34 0-8.64.17-12.895.498 85.149 6.578 152.194 77.754 152.194 164.59v153.061c0 73.388-59.492 132.88-132.88 132.88h25.789c73.388 0 132.88-59.492 132.88-132.88v-153.06C394.086 73.912 320.173 0 228.997 0z"
      fill="#3b4254"
    />
    <path
      d="M370.396 473.306H87.125l2.389-37.899c1.775-28.125 21.583-51.848 48.947-58.606l41.164-10.172a4.54 4.54 0 0 0 3.459-4.415v-92.137h91.818v92.25a4.55 4.55 0 0 0 3.459 4.414L319.06 376.8c27.363 6.758 47.172 30.481 48.947 58.606l2.389 37.9z"
      fill="#f9c295"
    />
    <path
      d="M368.006 435.406c-1.775-28.125-21.583-51.848-48.947-58.606l-40.698-10.058a4.55 4.55 0 0 1-3.459-4.414v-92.25h-23.351v92.25a4.55 4.55 0 0 0 3.459 4.414l40.698 10.058c27.363 6.758 47.172 30.481 48.947 58.606l2.389 37.899h23.351l-2.389-37.899z"
      fill="#e8a26a"
    />
    <path
      d="M183.085 347.097c15.442 8.335 31.398 12.692 45.913 12.692 14.513 0 30.466-4.357 45.905-12.69v-77.022h-91.818v77.02z"
      fill="#e8a26a"
    />
    <path
      d="M347.395 221.012c-14.248 73.045-73.281 115.082-118.398 115.082-42.495 0-97.351-37.292-115.471-102.679 80.759-11.501 148.837-86.378 165.874-129.83 0 .001 0 95.687 67.995 117.427z"
      fill="#f9c295"
    />
    <path
      d="M316.539 202.727c-3.831 79.898-54.27 128.291-94.965 132.986 2.507.243 4.987.381 7.423.381 45.117 0 104.15-42.037 118.398-115.083-12.418-3.97-22.559-10.413-30.856-18.284z"
      fill="#e8a26a"
    />
    <path
      d="M366.189 35.386c-5.609 20.652-13.408 28.451-34.06 34.06-4.281 1.163-4.281 7.25 0 8.413 20.652 5.609 28.451 13.408 34.06 34.059 1.163 4.281 7.25 4.281 8.413 0 5.609-20.652 13.408-28.451 34.06-34.059 4.281-1.163 4.281-7.25 0-8.413-20.652-5.609-28.451-13.408-34.06-34.06-1.162-4.28-7.25-4.28-8.413 0zM403.516 360.12l-53.887-49.801c-10.325-9.542-6.888-26.629 6.323-31.438l66.424-24.177c13.211-4.809 26.827 6.072 25.051 20.019l-9.268 72.788-34.643 12.609z"
      fill="#fee9a1"
    />
    <path
      d="M422.375 254.704l-16.835 6.127c10.566-3.845 22.474 7.657 21.91 21.162l-2.946 70.487 13.655-4.97 9.268-72.788c1.776-13.946-11.84-24.827-25.052-20.018z"
      fill="#fdca67"
    />
    <g fill="#dda84a">
      <path d="M425.285 280.472c-1.341-7.685-4.625-16.633-8.933-23.575l-14.498 5.277c2.998 3.891 7.096 11.948 8.653 20.877l3.397 19.459a7.502 7.502 0 0 0 14.778-2.58l-3.397-19.458zM370.284 300.488l9.906 17.091a7.497 7.497 0 0 0 10.25 2.728 7.5 7.5 0 0 0 2.729-10.25l-9.907-17.092c-4.545-7.841-6.586-16.646-6.79-21.554l-14.491 5.274c1.162 8.089 4.391 17.054 8.303 23.803z" />
    </g>
    <path
      d="M400.781 365.288l29.907 82.169c4.301 11.817 17.368 17.91 29.185 13.609s17.91-17.368 13.609-29.185l-29.907-82.169a7.617 7.617 0 0 0-9.761-4.552l-28.482 10.367a7.615 7.615 0 0 0-4.551 9.761z"
      fill="#fd7ba2"
    />
    <path
      d="M473.483 431.881l-29.907-82.169a7.616 7.616 0 0 0-9.761-4.552l-14.855 5.407c2.305-.839 5.339 1.685 6.778 5.637l29.907 82.169c3.596 9.881 2.704 19.598-1.71 23.99a22.81 22.81 0 0 0 5.938-1.298c11.818-4.3 17.911-17.367 13.61-29.184z"
      fill="#e9488e"
    />
    <path
      fill="#606572"
      d="M455.236 381.75l-5.13-14.096h-.001l-42.793 15.576h-.001l5.131 14.096 42.794-15.576z"
    />
    <path
      fill="#3b4254"
      d="M450.105 367.654l-17.837 6.493 5.13 14.096 17.838-6.493-5.13-14.096z"
    />
    <g>
      <path
        d="M115.378 326.49v73.861H47.331V295.162c0-1.273.287-2.445.873-3.468 4.229-7.312 22.482-4.666 40.785 5.901 15.734 9.083 26.389 21.06 26.389 28.895z"
        fill="#ff7870"
      />
      <path
        d="M92.573 299.772V400.35h22.805v-73.86c0-7.223-9.062-17.967-22.805-26.718z"
        fill="#d7352e"
      />
      <path
        d="M115.445 400.351H47.264c-5.592 0-10.126 4.534-10.126 10.126v91.398c0 5.592 4.533 10.126 10.126 10.126h68.181c5.592 0 10.126-4.534 10.126-10.126v-91.398c0-5.593-4.534-10.126-10.126-10.126z"
        fill="#78baf6"
      />
      <path
        d="M115.445 400.351H92.64c5.592 0 10.126 4.534 10.126 10.126v91.397c0 5.592-4.533 10.126-10.126 10.126h22.805c5.592 0 10.126-4.534 10.126-10.126v-91.397c0-5.593-4.534-10.126-10.126-10.126z"
        fill="#1885da"
      />
      <path
        d="M115.378 326.49c0 1.285-.287 2.458-.886 3.481-4.217 7.312-22.483 4.666-40.785-5.901-15.733-9.083-26.375-21.073-26.375-28.908 0-1.273.287-2.445.873-3.468 4.229-7.312 22.482-4.666 40.785 5.901 15.733 9.083 26.388 21.06 26.388 28.895z"
        fill="#ff9794"
      />
      <path fill="#606572" d="M37.138 423.397h88.433v15H37.138z" />
      <path fill="#3b4254" d="M102.766 423.397h22.805v15h-22.805z" />
    </g>
  </svg>
);

export default MakeupListIcon;
