import React from 'react'
import { styled } from '@mui/material/styles';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { RouteComponentProps, navigate } from '@reach/router';
import { CircularProgress } from '@mui/material';
import Layout from '../../components/Layout/Layout';
import Form from '../../components/Blacklist/Form/Form';
import { RootState } from '../../redux';
import { updateBlacklist, findOneBlacklist, UpdateBlacklist, FindOneBlacklist } from '../../redux/modules/blacklists/blacklists.thunks';
import { connect } from 'react-redux';
import { BlacklistData, UpdateBlacklistDTO } from '../../redux/modules/blacklists/blacklists.interfaces';

const PREFIX = 'Update';

const classes = {
  loaderContainer: `${PREFIX}-loaderContainer`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  }
}));

interface Props {
  id: string,
}

interface UpdatePageReduxProps {
  isLoading: boolean,
  blacklistData: BlacklistData,
  updateBlacklist: UpdateBlacklist,
  findOneBlacklist: FindOneBlacklist,
}

const UpdatePage: React.FunctionComponent<RouteComponentProps & Props & UpdatePageReduxProps & WithSnackbarProps> = ({ 
  id, enqueueSnackbar, isLoading, blacklistData, updateBlacklist, findOneBlacklist, location
}) => {

  
  const submitForm = async (values: UpdateBlacklistDTO) => {
    try {
      await updateBlacklist(id, {
        ...values,
      });
      enqueueSnackbar('Osoba z czarnej listy została zmodyfikowana', { variant: 'success'});
      navigate('/blacklist');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  };

  React.useEffect(() => {
    findOneBlacklist(id);
  }, [id, findOneBlacklist]);

  return (
    <StyledLayout>
      { isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}

      { blacklistData._id && (
        <Form
          isLoading={isLoading}
          onFormSubmit={submitForm}
          onFormCancel={() => navigate(`/blacklist/${id}`)}
          formSubmitButtonText="Zaktualizuj"
          firstName={blacklistData.firstName}
          lastName={blacklistData.lastName}
          source={blacklistData.source}
          notes={blacklistData.notes}
          phone={blacklistData.phone}
        />
      )}
      
    </StyledLayout>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.blacklists.isLoading, 
  blacklistData: state.blacklists.blacklist,
})

const mapDispatchToProps = {
  updateBlacklist,
  findOneBlacklist,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(UpdatePage));
