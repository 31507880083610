import { BlacklistsState, BlacklistData } from "./blacklists.interfaces";

const state: BlacklistsState = {
  blacklist: {} as BlacklistData,
  blacklists: [],
  isLoading: false,
  blacklistsCount: 0,
}

export default state;
