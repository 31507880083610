import { createSlice } from "redux-starter-kit";
import state from "./reserveList.state";
import { setAllReducer, setOneReducer, updateReducer, removeReducer, setIsLoadingReducer, addNewReserveListReducer } from "./reserveList.reducers";

const reserveListSlice = createSlice({
  slice: 'reserveList',

  initialState: state,

  reducers: {
    setAll: setAllReducer,
    setOne: setOneReducer,
    update: updateReducer,
    remove: removeReducer,
    addNewReserveList: addNewReserveListReducer,
    setIsLoading: setIsLoadingReducer,
  }
})
// Extract the action creators object and the reducer
const { actions, reducer, selectors } = reserveListSlice
// Extract and export each action creator by name
export const { setAll, setOne, update, remove, addNewReserveList, setIsLoading } = actions

export const { getReserveList } = selectors;
// Export the reducer, either as a default or named export
export default reducer
