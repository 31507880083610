import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Router, Redirect } from '@reach/router';

import ListPage from './pages/List';
import CreatePage from './pages/Create';
import UpdatePage from './pages/Update';
import DetailsPage from './pages/Details';
import SingInPage from './pages/SingIn';
import ArchivePage from './pages/Archive';

import ListClientsPage from './pages/ClientsDatabase/List';
import CreateClientsPage from './pages/ClientsDatabase/Create';
import UpdateClientsPage from './pages/ClientsDatabase/Update';
import DetailsClientsPage from './pages/ClientsDatabase/Details';

import ListBlacklistPage from './pages/Blacklist/List';
import CreateBlacklistPage from './pages/Blacklist/Create';
import UpdateBlacklistPage from './pages/Blacklist/Update';
import DetailsBlacklistPage from './pages/Blacklist/Details';

import ListReserveListPage from './pages/ReserveList/List';
import CreateReserveListPage from './pages/ReserveList/Create';
import UpdateReserveListPage from './pages/ReserveList/Update';
import DetailsReserveListPage from './pages/ReserveList/Details';

import { getUsers } from './redux/modules/users';
import { CircularProgress } from '@mui/material';
import { authCheck } from './redux/modules/users/users.thunks';

export interface PrivateRouteProps {
  component: any,
  path: string,
}

const PrivateRoute = ({ component, ...rest }: PrivateRouteProps) => {
  const auth = useSelector(getUsers);
  const Component = component;
  
  if (auth && auth.logout) {
    return <Redirect from={rest.path} to="/login" noThrow />;
  }

  return <Component {...rest} />;
}

const Main = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authCheck())
  }, [dispatch]);
  
  const auth = useSelector(getUsers);

  return (
    auth && auth.isLoginChecked ? (
      <Router>
        <SingInPage path="/login/" />
        <PrivateRoute path="/" component={ListPage} />
        <PrivateRoute path="/archive" component={ArchivePage} />
        <PrivateRoute path="/create/" component={CreatePage} />
        <PrivateRoute path="/makeup/:id/" component={DetailsPage} />
        <PrivateRoute path="/makeup/:id/edit" component={UpdatePage} />

        <PrivateRoute path="/client" component={ListClientsPage} />
        <PrivateRoute path="/client/create/" component={CreateClientsPage} />
        <PrivateRoute path="/client/:phone/" component={DetailsClientsPage} />
        <PrivateRoute path="/client/:id/edit" component={UpdateClientsPage} />

        <PrivateRoute path="/blacklist" component={ListBlacklistPage} />
        <PrivateRoute path="/blacklist/create/" component={CreateBlacklistPage} />
        <PrivateRoute path="/blacklist/:id/" component={DetailsBlacklistPage} />
        <PrivateRoute path="/blacklist/:id/edit" component={UpdateBlacklistPage} />

        <PrivateRoute path="/reserve-list" component={ListReserveListPage} />
        <PrivateRoute path="/reserve-list/create/" component={CreateReserveListPage} />
        <PrivateRoute path="/reserve-list/:id/" component={DetailsReserveListPage} />
        <PrivateRoute path="/reserve-list/:id/edit" component={UpdateReserveListPage} />
      </Router>
    ) : (
      <CircularProgress />
    )
  )
}

export default Main;
