import { ClientsState, ClientData, GroupedClientData } from "./clients.interfaces";

const state: ClientsState = {
  client: {} as GroupedClientData,
  clients: [],
  clientToEdit: {} as ClientData,
  searchClients: [],
  isLoading: false,
  clientsCount: 0,
  query: '',
}

export default state;
