import { createSlice } from "redux-starter-kit";
import state from "./users.state";
import { loginReducer, logoutReducer, isLoginCheckedReducer, setUserDataReducer } from './users.reducers';

const usersSlice = createSlice({
  slice: 'users',

  initialState: state,

  reducers: {
    login: loginReducer,
    logout: logoutReducer,
    isLoginChecked: isLoginCheckedReducer,
    setUserData: setUserDataReducer,
  }
})
// Extract the action creators object and the reducer
const { actions, reducer, selectors } = usersSlice
// Extract and export each action creator by name
export const { login, logout, isLoginChecked, setUserData } = actions

export const { getUsers } = selectors;
// Export the reducer, either as a default or named export
export default reducer
