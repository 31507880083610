import React, { useEffect, Fragment } from 'react'
import { RouteComponentProps, navigate, Link } from '@reach/router';
import {
  Grid,
  Paper,
  CircularProgress,
  Typography,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Checkbox,
  List,
  ListItem as MListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import Layout from '../../components/Layout/Layout';
import ListItem from '../../components/ClientsDatabase/ListItem/ListItem';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { deleteClient, getClientDetails, DeleteClient, GetClientDetails } from '../../redux/modules/clients/clients.thunks';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import { GroupedClientData, GroupedClientDataValue } from '../../redux/modules/clients/clients.interfaces';
import dayjs from '../../libs/dayjs';
import clsx from 'clsx';
import { CameraIcon, PeopleCount, Category } from '../../components/ListItem/ListItem.styled';
import { setClientToEdit } from '../../redux/modules/clients';
import { MakeupType } from '../../redux/modules/makeups/makeups.interfaces';
import styled from "styled-components";
import DynamicListItem from "../../components/ListItem/DynamicListItem";

const PREFIX = 'CLIENTDATABASEDETAILS';
const classes = {
  paper: `${PREFIX}-paper`,
  loaderContainer: `${PREFIX}-loaderContainer`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  removeButton: `${PREFIX}-removeButton`,
  button: `${PREFIX}-button`,
  listItemHeader: `${PREFIX}-listItemHeader`,
  expansionPanelContent: `${PREFIX}-expansionPanelContent`,
  missingNumberName: `${PREFIX}-missingNumberName`,
};

const StyledLayout = styled(Layout)((
    {
      theme
    }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    width: '100%'
  },
  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  },
  [`& .${classes.buttonsContainer}`]: {
    marginLeft: 'auto',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '1rem',
  },
  [`& .${classes.button}`]: {
    padding: '6px',
    minWidth: '0',
  },
  [`& .${classes.listItemHeader}`]: {
    display: 'flex',
  },
  [`& .${classes.expansionPanelContent}`]: {
    flexDirection: 'column',
  },
  [`& .${classes.missingNumberName}`]: {
    marginTop: 6,
  }
}));

interface Props {
  phone: string,
}

interface DetailsClientPageReduxProps {
  isLoading: boolean,
  clientData: GroupedClientData,
  deleteClient: DeleteClient,
  getClientDetails: GetClientDetails,
  setClientToEdit: typeof setClientToEdit,
}

const DetailsClientPage: React.FunctionComponent<RouteComponentProps & Props & WithSnackbarProps & DetailsClientPageReduxProps> = ({ 
  phone, enqueueSnackbar, isLoading, clientData, setClientToEdit, deleteClient, getClientDetails
}) => {
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
  const [toRemoveId, setToRemoveId] = React.useState('');
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const makeupCategory = React.useCallback((type: MakeupType) => {
    switch (type) {
      case MakeupType.BREAK:
        return 'Przerwa';
      case MakeupType.MODEL:
        return 'Modelka';
      case MakeupType.LESSON:
        return 'Lekcja makijażu';
      case MakeupType.MAKEUP:
        return 'Makijaż';
      case MakeupType.RESERVATION:
        return 'Rezerwacja terminu';
      case MakeupType.TRIAL_WEDDING:
        return 'Makijaż próbny ślubny';
      case MakeupType.GROOM:
        return 'Poprawka pana młodego';
      case MakeupType.WEDDING:
        return 'Makijaż ślubny';
      default:
        return 'Makijaż';
    }
  }, []);

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDelete = async () => {
    try {
      await deleteClient(toRemoveId);
      enqueueSnackbar('Klient został usunięty', { variant: 'success'});
      setOpenRemoveDialog(false);
      getClientDetails(phone, {
        force: true,
      });
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  }

  useEffect(() => {
    getClientDetails(phone, { force: true });
  }, [phone, getClientDetails]);

  const renderNotes = (item: GroupedClientDataValue) => {
    if (!item.notes) {
      return null;
    }
    return item.notes.split('\n').map((text, index) => (
        <p key={index}>{text}</p>
    ))
  }

  return (
    <StyledLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            { isLoading && (
              <div className={classes.loaderContainer}>
                <CircularProgress />
              </div>
            )}

            { clientData.phone && (
              <Fragment>
                <Link to={`/client/${clientData.phone}`}>
                  <ListItem
                    details
                    {...clientData}
                  />
                </Link>
                <Typography variant="body2" style={{ marginBottom: '8px' }}>
                  Telefon: { clientData.phone }
                </Typography>
                <FormControlLabel
                  control={<Checkbox checked={clientData.goodForPhotos} />}
                  label="Fajna do sesji"
                />
                <FormControlLabel
                    control={<Checkbox checked={clientData.pinned} />}
                    label="Przypięta"
                />
              </Fragment>
            )}
          </Paper>
          <List>
            { clientData.values && clientData.values.map((item) => (
              <MListItem key={item._id}>
                <Accordion
                  expanded={expanded === item._id}
                  onChange={handleChange(item._id)}
                  style={{
                    width: '100%',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<Icon>expand_more</Icon>}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Category style={{ margin: 'auto 8px 3px 0'}}>{makeupCategory(item.type)}</Category>
                    <Typography variant="body1">
                      { dayjs(item.date).format('dddd, DD MMMM YYYY') }
                      <CameraIcon>{ item.notes.length > 0 && '📝' }</CameraIcon>
                    </Typography>
                    { item.peopleCount > 1 && <PeopleCount style={{ margin: "0 10px 0 4px" }}>{ item.peopleCount }</PeopleCount> }
                    { clientData.phone === "+48" && (
                      <Typography variant="body2" className={classes.missingNumberName}>
                        { item.firstName + ' ' + item.lastName }
                      </Typography>
                    )}
                  </AccordionSummary>
                  <AccordionDetails className={classes.expansionPanelContent}>
                    <div className={classes.listItemHeader}>
                      <div className={classes.buttonsContainer}>
                        <Button
                            variant="contained"
                            className={classes.button}
                            color="primary"
                            onClick={() => {
                              navigate(`/makeup/${item.makeupId}`);
                            }}
                        >
                          <Icon>brush</Icon>
                        </Button>
                        <Button
                          variant="contained"
                          className={clsx(classes.removeButton, classes.button)}
                          onClick={() => {
                            setOpenRemoveDialog(true);
                            setToRemoveId(item._id);
                          }}
                        >
                          <Icon>delete</Icon>
                        </Button>
                        <Button
                          variant="contained"
                          className={classes.button}
                          color="primary"
                          onClick={() => {
                            setClientToEdit({
                              ...item,
                              firstName: clientData.firstName,
                              lastName: clientData.lastName,
                              phone: clientData.phone,
                              pinned: clientData.pinned,
                            });
                            navigate(`/client/${item._id}/edit`);
                          }}
                        >
                          <Icon>edit</Icon>
                        </Button>
                      </div>
                    </div>
                    <div>
                      {expanded === item._id && (
                          <DynamicListItem makeupId={item.makeupId} />
                      )}
                      <Typography variant="body1">
                        {renderNotes(item)}
                      </Typography>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </MListItem>
            ))}
          </List>
        </Grid>
      </Grid>

      <Dialog
        open={openRemoveDialog}
        onClose={() => setOpenRemoveDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Czy chcesz usunąć wizytę?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenRemoveDialog(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={() => handleDelete()} color="primary" autoFocus>
            Usuń
          </Button>
        </DialogActions>
      </Dialog>
    </StyledLayout>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.clients.isLoading, 
  clientData: state.clients.client,
})

const mapDispatchToProps = {
  deleteClient,
  getClientDetails,
  setClientToEdit,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DetailsClientPage));
