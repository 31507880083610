import { createSlice } from "redux-starter-kit";
import state from "./blacklists.state";
import { setAllReducer, setOneReducer, setIsLoadingReducer } from "./blacklists.reducers";

const blacklistsSlice = createSlice({
  slice: 'blacklists',

  initialState: state,

  reducers: {
    setAll: setAllReducer,
    setOne: setOneReducer,
    setIsLoading: setIsLoadingReducer,
  }
})
// Extract the action creators object and the reducer
const { actions, reducer, selectors } = blacklistsSlice
// Extract and export each action creator by name
export const { setAll, setOne, setIsLoading } = actions

export const { getBlacklists } = selectors;
// Export the reducer, either as a default or named export
export default reducer
