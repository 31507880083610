import { BlacklistsState, BlacklistData, BlacklistDataDTO } from "./blacklists.interfaces";
import { PayloadAction } from "redux-starter-kit";
import { IsLoadingPayload } from "../../redux.interfaces";

export const setAllReducer = (state: BlacklistsState, action: PayloadAction<BlacklistDataDTO>) => {
  state.blacklists = action.payload.data;
  state.blacklistsCount = action.payload.count;
}

export const setOneReducer = (state: BlacklistsState, action: PayloadAction<BlacklistData>) => {
  state.blacklist = action.payload;
}

export const setIsLoadingReducer = (state: BlacklistsState, action: PayloadAction<IsLoadingPayload>) => {
  state.isLoading = action.payload.isLoading;
}
