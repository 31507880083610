import { createSlice } from "redux-starter-kit";
import state from "./base.state";
import {setArchiveFiltersReducer, setRewindIdReducer} from "./base.reducers";

const blacklistsSlice = createSlice({
  slice: 'base',

  initialState: state,

  reducers: {
    setRewindId: setRewindIdReducer,
    setArchiveFilters: setArchiveFiltersReducer,
  }
})
// Extract the action creators object and the reducer
const { actions, reducer, selectors } = blacklistsSlice;
// Extract and export each action creator by name
export const { setRewindId, setArchiveFilters } = actions;

export const { getBase } = selectors;
// Export the reducer, either as a default or named export
export default reducer
