import React, {CSSProperties} from 'react';
import dayjs from '../../libs/dayjs';
import {CardContent, Icon, Typography, Chip, Stack} from '@mui/material';
import {
    CameraIcon, CardFooter, Category,
    ListItemAction,
    ListItemActions,
    ListItemContainer,
    ListItemContent,
    ListItemRow,
    Place
} from '../ListItem/ListItem.styled';
import {Link} from '@reach/router';
import {ReserveListData} from "../../redux/modules/reserveList/reserveList.interfaces";

interface Props {
    details?: boolean,
    style?: CSSProperties;
}

const ListItem: React.FunctionComponent<Props & ReserveListData> = ({
   details, style, ...reserveListEntry
}) => {
    const parsedDate = dayjs(reserveListEntry.date).format('DD MMMM YYYY');

    const wrapWithLink = (children: JSX.Element) => {
        if (details) {
            return children;
        }

        return (
            <Link to={`/reserve-list/${reserveListEntry._id}`} style={style}>
                {children}
            </Link>
        )
    }

    return wrapWithLink(
        <ListItemContainer id={reserveListEntry._id} details={details}>
            <span id={`${reserveListEntry._id}-anchor`} style={{ position: 'absolute', top: '-150px'}}></span>
            <CardContent>
                <ListItemRow>
                    <ListItemContent>
                        <Category>Wpis na liste rezerwową</Category>
                        <Typography variant="subtitle2">{parsedDate}{' '}</Typography>
                        <Place variant="subtitle1">
                            { reserveListEntry.notes.length > 0 && <CameraIcon><span role="img" aria-label="emoji">📝</span></CameraIcon>}
                        </Place>
                        {(reserveListEntry.source === 'FB' || reserveListEntry.firstName !== 'Lista') && (
                            <Typography variant="body1">{reserveListEntry.firstName} {reserveListEntry.lastName}</Typography>
                        )}
                        {reserveListEntry.source === 'Instagram' && (
                            <Typography variant="body1">{reserveListEntry.instagram}</Typography>
                        )}
                        {!['FB', "Instagram"].includes(reserveListEntry.source) && (
                            <Typography variant="body1">{reserveListEntry.phone}</Typography>
                        )}
                    </ListItemContent>
                    <ListItemActions>
                        <ListItemAction href={`tel:${reserveListEntry.phone}`} onClick={(e) => e.stopPropagation()}>
                            <Icon>phone</Icon>
                        </ListItemAction>
                        <ListItemAction href={`sms:${reserveListEntry.phone}`} onClick={(e) => e.stopPropagation()}>
                            <Icon>message</Icon>
                        </ListItemAction>
                    </ListItemActions>
                </ListItemRow>
            </CardContent>
            <CardFooter details={details}>
                <Stack direction="row" spacing={1}>
                    <div>
                        {reserveListEntry.tags?.map(tag => (
                            <Chip color="primary" label={tag} />
                        ))}
                    </div>
                </Stack>
            </CardFooter>
        </ListItemContainer>
    )
}

export default ListItem;
