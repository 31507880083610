import { configureStore } from 'redux-starter-kit'
import baseReducer from './modules/base'
import usersReducer from './modules/users'
import makeupsReducer from './modules/makeups'
import clientsReducer from './modules/clients'
import blacklistsReducer from './modules/blacklists'
import reserveListReducer from './modules/reserveList'

const store = configureStore({
  reducer: {
    base: baseReducer,
    users: usersReducer,
    makeups: makeupsReducer,
    clients: clientsReducer,
    blacklists: blacklistsReducer,
    reserveList: reserveListReducer
  },
})

export default store

export type RootState = ReturnType<typeof store.getState>;
