import React from 'react'
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { RouteComponentProps, navigate } from '@reach/router';
import Layout from '../../components/Layout/Layout';
import Form from '../../components/Blacklist/Form/Form';
import { connect } from 'react-redux';
import { CreateBlacklistFormData } from '../../redux/modules/blacklists/blacklists.interfaces';
import { createBlacklist, CreateBlacklist } from '../../redux/modules/blacklists/blacklists.thunks';
import { RootState } from '../../redux';

const defaultValues = {
  firstName: '',
  lastName: '',
  source: 'Olx',
  phone: '+48',
  notes: '',
}

interface CreatePageReduxProps {
  isLoading: boolean,
  createBlacklist: CreateBlacklist,
}

const CreatePage: React.FunctionComponent<RouteComponentProps & WithSnackbarProps & CreatePageReduxProps> = ({
  enqueueSnackbar, createBlacklist, isLoading,
}) => {
  const submitForm = async (values: CreateBlacklistFormData) => {
    try {
      await createBlacklist({
        ...values,
      });
      enqueueSnackbar('Osoba został dodana do czarnej listy', { variant: 'success'});
      navigate('/blacklist');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  };

  return (
    <Layout>
      <Form
        isLoading={isLoading}
        onFormSubmit={submitForm}
        onFormCancel={() => navigate('/blacklist')}
        formSubmitButtonText="Dodaj"
        {...defaultValues}
      />
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.makeups.isLoading, 
})

const mapDispatchToProps = {
  createBlacklist,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(CreatePage));
