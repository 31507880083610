import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  TextField,
  Paper,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { CreateClientFormData } from '../../../redux/modules/clients/clients.interfaces';
import PhoneInput from "../../Form/PhoneInput";

const PREFIX = 'Form';

const classes = {
  paper: `${PREFIX}-paper`,
  form: `${PREFIX}-form`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  loaderContainer: `${PREFIX}-loaderContainer`,
  formControl: `${PREFIX}-formControl`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  },

  [`& .${classes.formControl}`]: {
    marginTop: '16px',
    marginBottom: '8px',
  }
}));

interface Props {
  isLoading: boolean,
  onFormCancel: () => any,
  onFormSubmit: (values: CreateClientFormData) => Promise<any>,
  formSubmitButtonText: string,
}

const Form: React.FunctionComponent<CreateClientFormData & Props> = ({ 
  firstName, lastName, phone = '+48', date, notes, goodForPhotos, fromPhoto, photoTaken, pinned, isLoading, onFormCancel, onFormSubmit, formSubmitButtonText,
}) => {
  const [values, setValues] = React.useState<CreateClientFormData>({
    firstName,
    lastName,
    phone,
    date,
    notes,
    goodForPhotos,
    fromPhoto,
    photoTaken,
    pinned,
  });

  const handleChange = (name: keyof CreateClientFormData) => (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleCheckboxChange = (name: keyof CreateClientFormData) => (event: React.ChangeEvent<HTMLInputElement | { checked: unknown }>) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  return (
    <StyledGrid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <form noValidate autoComplete="off" className={classes.form}>
            <TextField
              id="firstName"
              label="Imię"
              value={values.firstName}
              onChange={handleChange('firstName')}
              margin="normal"
            />

            <TextField
              id="lastName"
              label="Nazwisko"
              value={values.lastName}
              onChange={handleChange('lastName')}
              margin="normal"
            />

            <FormControl className={classes.formControl}>
              <TextField
                  label="Telefon kontaktowy"
                  id="phone"
                  value={values.phone}
                  onChange={handleChange('phone')}
                  InputProps={{
                    inputComponent: PhoneInput as any,
                  }}
              />
            </FormControl>

            <TextField
              id="date"
              label="Data makijażu"
              type="date"
              value={values.date}
              onChange={handleChange('date')}
              margin="normal"
            />

            <FormControlLabel
              control={<Checkbox checked={values.goodForPhotos} onChange={handleCheckboxChange('goodForPhotos')} value="goodForPhotos" />}
              label="Fajna do sesji"
            />

            <FormControlLabel
              control={<Checkbox checked={values.fromPhoto} onChange={handleCheckboxChange('fromPhoto')} value="fromPhoto" />}
              label="Od fotograf"
            />

            <FormControlLabel
              control={<Checkbox checked={values.photoTaken} onChange={handleCheckboxChange('photoTaken')} value="photoTaken" />}
              label="Zrobiłam zdjęcia"
            />

            <FormControlLabel
                control={<Checkbox checked={values.pinned} onChange={handleCheckboxChange('pinned')} value="pinned" />}
                label="Przypięta"
            />

            <TextField
              id="standard-multiline-flexible"
              label="Notatki"
              multiline
              value={values.notes}
              onChange={handleChange('notes')}
              margin="normal"
              disabled
            />
          </form>
          { isLoading && (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          )}
          <div className={classes.buttonContainer}>
            <Button onClick={onFormCancel}>Anuluj</Button>
            <Button variant="contained" color="primary" onClick={() => onFormSubmit(values)}>{formSubmitButtonText}</Button>
          </div>
        </Paper>
      </Grid>
    </StyledGrid>
  );
}

export default Form;
