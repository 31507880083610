import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import axios, { AxiosResponse } from "axios";
import { LoginResponse, LoginData, UserData } from "./users.interface";
import { login, isLoginChecked, setUserData, logout } from ".";
import { RootState } from "../..";

export type FetchLogin = (data: LoginData) => Promise<void>;

export const fetchLogin = (data: LoginData):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  const response: AxiosResponse<LoginResponse> = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, data);
  dispatch(login(response.data));
}

export const authCheck = ():ThunkAction<void, RootState, null, AnyAction> => async dispatch => {
  const token = localStorage.getItem('jwt_token');

  if (!token) {
    dispatch(logout());
    dispatch(isLoginChecked({ checked: true }));
    return;
  }

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;

  try {
    const response: AxiosResponse<UserData> = await axios.get(`${process.env.REACT_APP_API_URL}/user`);
    dispatch(setUserData(response.data));
  } catch (e: any) {
    if (e.response) {
      dispatch(logout());
    }
    return;
  } finally {
    dispatch(isLoginChecked({ checked: true }));
  }
}