import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  TextField,
  Paper,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { CreateBlacklistFormData } from '../../../redux/modules/blacklists/blacklists.interfaces';
import {SelectChangeEvent} from "@mui/material/Select/SelectInput";
import PhoneInput from "../../Form/PhoneInput";

const PREFIX = 'Form';

const classes = {
  paper: `${PREFIX}-paper`,
  form: `${PREFIX}-form`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  loaderContainer: `${PREFIX}-loaderContainer`,
  formControl: `${PREFIX}-formControl`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  },

  [`& .${classes.formControl}`]: {
    marginTop: '16px',
    marginBottom: '8px',
  }
}));

interface Props {
  isLoading: boolean,
  onFormCancel: () => any,
  onFormSubmit: (values: CreateBlacklistFormData) => Promise<any>,
  formSubmitButtonText: string,
}

const Form: React.FunctionComponent<CreateBlacklistFormData & Props> = ({ 
  firstName, lastName, source, phone, notes, isLoading, onFormCancel, onFormSubmit, formSubmitButtonText,
}) => {
  const [values, setValues] = React.useState<CreateBlacklistFormData>({
    firstName,
    lastName,
    source,
    phone,
    notes,
  });

  const handleChange = (name: keyof CreateBlacklistFormData) => (event: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSelectChange = (name: keyof CreateBlacklistFormData) => (event: SelectChangeEvent) => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <StyledGrid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <form noValidate autoComplete="off" className={classes.form}>
            <TextField
              id="firstName"
              label="Imię"
              value={values.firstName}
              onChange={handleChange('firstName')}
              margin="normal"
            />

            <TextField
              id="lastName"
              label="Nazwisko"
              value={values.lastName}
              onChange={handleChange('lastName')}
              margin="normal"
            />

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="source">Skąd napisała?</InputLabel>
              <Select
                label="Skąd napisała?"
                native
                value={values.source}
                onChange={handleSelectChange('source')}
                inputProps={{
                  name: 'source',
                  id: 'source',
                }}
              >
                <option value="" />
                <option value="Olx">Olx</option>
                <option value="Olx/SMS">Olx/SMS</option>
                <option value="Foto">Foto</option>
                <option value="Instagram">Instagram</option>
                <option value="FB">FB</option>
                <option value="SMS">SMS</option>
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                  label="Telefon kontaktowy"
                  id="phone"
                  value={values.phone}
                  onChange={handleChange('phone')}
                  InputProps={{
                    inputComponent: PhoneInput as any,
                  }}
              />
            </FormControl>

            <TextField
              id="standard-multiline-flexible"
              label="Notatki"
              multiline
              rows={8}
              value={values.notes}
              onChange={handleChange('notes')}
              margin="normal"
            />
          </form>
          { isLoading && (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          )}
          <div className={classes.buttonContainer}>
            <Button onClick={onFormCancel}>Anuluj</Button>
            <Button variant="contained" color="primary" onClick={() => onFormSubmit(values)}>{formSubmitButtonText}</Button>
          </div>
        </Paper>
      </Grid>
    </StyledGrid>
  );
}

export default Form;
