import { MakeupsState, MakeupData, MakeupDataDTO } from "./makeups.interfaces";
import { PayloadAction } from "redux-starter-kit";
import { IsLoadingPayload } from "../../redux.interfaces";
import dayjs from '../../../libs/dayjs';

export const setAllReducer = (state: MakeupsState, action: PayloadAction<MakeupDataDTO>) => {
  state.makeups = action.payload.data;
  state.count = action.payload.count;
}

export const setOneReducer = (state: MakeupsState, action: PayloadAction<MakeupData>) => {
  state.makeup = action.payload;
}

export const addNewMakeupReducer = (
  state: MakeupsState, action: PayloadAction<MakeupData>
) => {
  const makeupToAdd = action.payload;
  const makeupDate = dayjs(makeupToAdd.date);
  const makeupTime = dayjs(makeupToAdd.time);

  const idx = state.makeups.findIndex((makeup) => {
    const date = dayjs(makeup.date);
    const time = dayjs(makeup.time);

    return (date.isSame(makeupDate) && makeupTime.isBefore(time)) || makeupDate.isBefore(date);
  });

  if (idx === -1) {
    state.makeups.push(makeupToAdd);
    return;
  }

  if (idx === 0) {
    state.makeups.unshift(makeupToAdd);
    return;
  }

  state.makeups.splice(idx, 0, makeupToAdd);
}

export const updateReducer = (state: MakeupsState, action: PayloadAction<MakeupData>) => {
  if (state.makeup._id === action.payload._id) {
    state.makeup = action.payload;
  }
  const idx = state.makeups.findIndex(makeup => makeup._id === action.payload._id);
  state.makeups[idx] = action.payload;
}

export const removeReducer = (state: MakeupsState, action: PayloadAction<string>) => {
  state.makeups = state.makeups.filter(makeup => makeup._id !== action.payload);
}

export const setIsLoadingReducer = (state: MakeupsState, action: PayloadAction<IsLoadingPayload>) => {
  state.isLoading = action.payload.isLoading;
}

export const setLocationKeyReducer = (state: MakeupsState, action: PayloadAction<string | undefined>) => {
  state.locationKey = action.payload;
}