import axios, { AxiosResponse } from "axios";
import { FindAllParams, MakeupData, CreateMakeupFormData, UpdateMakeupDTO, MakeupDataDTO } from "./makeups.interfaces";
import { setAll, setOne, update, remove, setIsLoading, addNewMakeup } from ".";
import { RootState } from "../..";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";

export const findAllMakeups = (params: FindAllParams = {}):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  const response: AxiosResponse<MakeupDataDTO> = await axios.get(`${process.env.REACT_APP_API_URL}/makeup/`, {
    params,
  });

  dispatch(setAll(response.data));
  dispatch(setIsLoading({ isLoading: false }));
}

export type FindOneMakeup = (id: string) => Promise<void>;

export const findOneMakeup = (id: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async (dispatch, getState) => {
  const { makeups } = getState();
  const makeup = makeups.makeups.find(curMakeup => curMakeup._id === id);

  if (makeup) {
    dispatch(setOne(makeup));
    return;
  }

  const response: AxiosResponse<MakeupData> = await axios.get(`${process.env.REACT_APP_API_URL}/makeup/${id}`);

  dispatch(setOne(response.data));
}

export type CreateMakeup = (data: CreateMakeupFormData) => Promise<MakeupData>;

export const createMakeup = (data: CreateMakeupFormData):ThunkAction<Promise<MakeupData>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    const response = await axios.post<MakeupData>(`${process.env.REACT_APP_API_URL}/makeup`, data);
    const makeup = response.data;
    dispatch(addNewMakeup(makeup));
    return makeup;
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export type UpdateMakeup = (id: string, data: UpdateMakeupDTO) => Promise<void>;

export const updateMakeup = (id: string, data: UpdateMakeupDTO):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    const response: AxiosResponse<MakeupData> = await axios.put(`${process.env.REACT_APP_API_URL}/makeup/${id}`, data);
    dispatch(update(response.data));
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export type DeleteMakeup = (id: string) => Promise<void>;

export const deleteMakeup = (id: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/makeup/${id}`);
    dispatch(remove(id));
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export type RestoreMakeup = (id: string) => Promise<void>;

export const restoreMakeup = (id: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await axios.patch(`${process.env.REACT_APP_API_URL}/makeup/${id}`);
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export const uploadFiles = (id: string, formData: FormData):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    const response: AxiosResponse<MakeupData> = await axios.put(`${process.env.REACT_APP_API_URL}/makeup/${id}/photos/upload`, formData);
    dispatch(update(response.data));
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export const deleteFile = (id: string, toRemove: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    const response: AxiosResponse<MakeupData> = await axios.delete(`${process.env.REACT_APP_API_URL}/makeup/${id}/photos/${toRemove}`);
    dispatch(update(response.data));
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}