import axios from "axios";
import { PayloadAction } from "redux-starter-kit";
import { UsersState, LoginResponse, UserData } from "./users.interface";

const loginReducer = (state: UsersState, action: PayloadAction<LoginResponse>) => {
  const { token, user } = action.payload;

  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  localStorage.setItem('jwt_token', token);

  state.auth = user;
  state.logout = false;
};

const logoutReducer = (state: UsersState) => {
  localStorage.removeItem('jwt_token');
  state.auth.email = '';
  state.logout = true;
};

const isLoginCheckedReducer = (state: UsersState, action: PayloadAction<{checked: boolean}>) => {
  state.isLoginChecked = action.payload.checked;
}

const setUserDataReducer = (state: UsersState, action: PayloadAction<UserData>) => {
  state.auth = action.payload.user;
}

export { loginReducer, logoutReducer, isLoginCheckedReducer, setUserDataReducer };
