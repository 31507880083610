import { UsersState } from "./users.interface";

const state: UsersState = {
  auth: {
    email: '',
  },
  logout: false,
  isLoginChecked: false,
};

export default state;
