import React, {useEffect} from 'react';
import ListItem from "./ListItem";
import {CircularProgress, Typography} from "@mui/material";
import {MakeupData, MakeupType} from "../../redux/modules/makeups/makeups.interfaces";
import axios, {AxiosResponse} from "axios";

interface Props {
    makeupId?: string;
}

const DynamicListItem: React.FC<Props> = ({ makeupId }) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [hasError, setHasError] = React.useState(false);
    const [makeupData, setMakeupData] = React.useState<MakeupData>();

    useEffect(() => {
        if (!makeupId) {
            return;
        }

        const fetch = async () => {
            const response: AxiosResponse<MakeupData> = await axios.get(`${process.env.REACT_APP_API_URL}/makeup/${makeupId}`);
            setMakeupData(response.data);
            setIsLoading(false);
        }

        fetch();
    }, [])

    if (!makeupId) {
        return (
            <Typography variant="body1">
                Klientka nie ma powiązanej wizyty
            </Typography>
        )
    }

    if (isLoading || !makeupData) {
        return <CircularProgress />
    }

    return (
        <>
            <ListItem details {...makeupData} />
            {makeupData.type !== MakeupType.MODEL && (
                <Typography variant="body2" style={{ marginBottom: '8px' }}>
                    Skąd napisała: { makeupData.source }
                </Typography>
            )}
            {makeupData.instagram && (
                <Typography variant="body2" style={{ marginBottom: '8px' }}>
                    Nick na instagramie: { makeupData.instagram } <a href={`https://instagram.com/${makeupData.instagram}`} target="_blank" rel="noopener noreferrer">Przejdź</a>
                </Typography>
            )}
        </>
    )
}

export default DynamicListItem;
