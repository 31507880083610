import styled, {css} from 'styled-components';
import {Card, CardActions, Typography} from '@mui/material';


export const ListItemContainer: any = styled<any>(Card)`
  margin: 16px;
  position: relative;
  
  + & {
    margin-top: 0;
  }

  ${props => props.details && `
    margin: 0;
    margin-bottom: 8px;
    box-shadow: none !important;

    > *:not(${CardFooter}) {
      padding: 0 !important;
    }
  `}
  
  ${props => props.break && !props.details && `
    background: rgba(189, 254, 233, 0.6) !important;
  `}

  ${props => props.model && !props.details && `
    background: rgba(230, 189, 255, 0.6) !important;
  `}

  ${props => props.reservation && !props.details && `
    background: #c7f2ffe0 !important;
  `}
`;

export const ListItemRow = styled.div`
  display: flex;
`;

export const ListItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const ListItemActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
`;

export const ListItemAction = styled.a`
  padding: 16px;
  color: #6a1b9a;
`;

export const Category = styled.div`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: 8px;
  color: #8E44AD;
`;

export const Place: any = styled(Typography)`
  display: flex;
  align-items: center;
  padding: 8px 0;
`;

export const CameraIcon = styled.span`
  margin-left: 8px;
  font-size: 24px;
  line-height: 1em;
`;

export const PeopleCount = styled.span`
  width: 28px;
  height: 28px;
  text-align: center;
  margin-left: 8px;
  font-size: 20px;
  line-height: 28px;
  border-radius: 50%;
  background: #D2F7F2;
`;

export const CardFooter = styled(CardActions)<{ details?: boolean }>`
  justify-content: space-between;
  
  ${props => !props.details && css`
    padding-top: 0!important;
  `}
  
  ${props => props.details && css`
    padding-left: 0!important;
    padding-bottom: 0!important;
    flex-direction: row-reverse;
  `}
`;
