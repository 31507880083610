import React from 'react'
import { styled } from '@mui/material/styles';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { RouteComponentProps, navigate } from '@reach/router';
import { CircularProgress } from '@mui/material';
import Layout from '../../components/Layout/Layout';
import dayjs from '../../libs/dayjs';
import Form from '../../components/ClientsDatabase/Form/Form';
import { RootState } from '../../redux';
import { updateClient, findOneClient, UpdateClient, FindOneClient } from '../../redux/modules/clients/clients.thunks';
import { connect } from 'react-redux';
import { ClientData, UpdateClientDTO } from '../../redux/modules/clients/clients.interfaces';

const PREFIX = 'Update';

const classes = {
  loaderContainer: `${PREFIX}-loaderContainer`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  }
}));

interface Props {
  id: string,
}

interface UpdateClientPageReduxProps {
  isLoading: boolean,
  clientData: ClientData,
  updateClient: UpdateClient,
  findOneClient: FindOneClient,
}

const UpdateClientPage: React.FunctionComponent<RouteComponentProps & Props & UpdateClientPageReduxProps & WithSnackbarProps> = ({ 
  id, enqueueSnackbar, isLoading, clientData, updateClient, findOneClient
}) => {

  
  const submitForm = async (values: UpdateClientDTO) => {
    try {
      await updateClient(id, {
        ...values,
        date: dayjs(values.date).toString(),
      });
      enqueueSnackbar('Klient został zmodyfikowany', { variant: 'success'});
      navigate('/client');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  };

  React.useEffect(() => {
    findOneClient(id);
  }, [id, findOneClient]);

  return (
    <StyledLayout>
      { isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}

      { clientData._id && (
        <Form
          isLoading={isLoading}
          onFormSubmit={submitForm}
          onFormCancel={() => navigate(`/client/${id}`)}
          formSubmitButtonText="Zaktualizuj"
          firstName={clientData.firstName}
          lastName={clientData.lastName}
          notes={clientData.notes}
          phone={clientData.phone}
          date={dayjs(clientData.date).format('YYYY-MM-DD')}
          goodForPhotos={clientData.goodForPhotos}
          fromPhoto={clientData.fromPhoto}
          photoTaken={clientData.photoTaken}
          pinned={clientData.pinned}
        />
      )}
      
    </StyledLayout>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.clients.isLoading, 
  clientData: state.clients.clientToEdit,
})

const mapDispatchToProps = {
  updateClient,
  findOneClient,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(UpdateClientPage));
