import React, { useState } from 'react';
import { connect } from 'react-redux';
import { navigate, RouteComponentProps, Redirect } from '@reach/router';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Icon, CircularProgress } from '@mui/material';

import { fetchLogin, FetchLogin } from '../redux/modules/users/users.thunks';
import { UsersState } from '../redux/modules/users/users.interface';
import { RootState } from '../redux';

const PREFIX = 'SingIn';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    height: '100vh',
  },

  [`& .${classes.image}`]: {
    // backgroundImage: 'url(https://source.unsplash.com/xwM61TPMlYk)',
    backgroundImage: 'url(/photo.jpeg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },

  [`& .${classes.paper}`]: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  }
}));

interface SingInPageReduxProps {
  auth: UsersState,
  fetchLogin: FetchLogin,
}

const SingInPage: React.FunctionComponent<RouteComponentProps & SingInPageReduxProps> = ({ auth, fetchLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  


  if (auth && !auth.logout) {
    return <Redirect from="/login" to="/" noThrow />
  }
  
  const loginHandler = async () => {
    setIsLoading(true);

    try {
      await fetchLogin({
        email,
        password,
      })
      
      setIsLoading(false);
      navigate('/');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <StyledGrid container className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5}>
        <Paper component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <Icon>lock_outlined</Icon>
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form
              className={classes.form}
              noValidate
              onSubmit={e => e.preventDefault()}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={e => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => loginHandler()}
              >
                { isLoading ? <CircularProgress /> : 'Sign In' }
              </Button>
            </form>
          </div>
        </Paper>
      </Grid>
    </StyledGrid>
  );
}

const mapStateToProps = (state: RootState) => ({
  auth: state.users, 
})

const mapDispatchToProps = {
  fetchLogin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingInPage);
