import React, { useEffect, Fragment } from 'react'
import { styled } from '@mui/material/styles';
import { RouteComponentProps, navigate } from '@reach/router';
import {
  Grid,
  Paper,
  CircularProgress,
  Typography,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogActions, CardActions,
} from '@mui/material';
import Layout from '../../components/Layout/Layout';
import ListItem from '../../components/Blacklist/ListItem/ListItem';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { deleteBlacklist, findOneBlacklist, DeleteBlacklist, FindOneBlacklist } from '../../redux/modules/blacklists/blacklists.thunks';
import { connect } from 'react-redux';
import { RootState } from '../../redux';
import { BlacklistData } from '../../redux/modules/blacklists/blacklists.interfaces';
import dayjs from "../../libs/dayjs";

const PREFIX = 'Details';

const classes = {
  paper: `${PREFIX}-paper`,
  loaderContainer: `${PREFIX}-loaderContainer`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  removeButton: `${PREFIX}-removeButton`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  },

  [`& .${classes.buttonsContainer}`]: {
    marginLeft: 'auto',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.removeButton}`]: {
    marginRight: '16px',
  }
}));

interface Props {
  id: string,
}

interface DetailsPageReduxProps {
  isLoading: boolean,
  blacklistData: BlacklistData,
  deleteBlacklist: DeleteBlacklist,
  findOneBlacklist: FindOneBlacklist,
}

const DetailsPage: React.FunctionComponent<RouteComponentProps & Props & WithSnackbarProps & DetailsPageReduxProps> = ({ 
  id, enqueueSnackbar, isLoading, blacklistData, deleteBlacklist, findOneBlacklist, location
}) => {
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
  const createdAtDate = dayjs(blacklistData.createdAt).format('DD.MM.YY HH:mm');
  const updatedAtDate = dayjs(blacklistData.updatedAt).format('DD.MM.YY HH:mm');

  const handleDelete = async () => {
    try {
      await deleteBlacklist(id);
      enqueueSnackbar('Osoba został usunięta z czarnej listy', { variant: 'success'});
      navigate('/blacklist');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await findOneBlacklist(id);
    }

    fetchData();
  }, [id, findOneBlacklist]);

  const renderNotes = () => {
    if (!blacklistData.notes) {
      return null;
    }
    return blacklistData.notes.split('\n').map((text, index) => (
        <p key={index}>{text}</p>
    ))
  }

  return (
    <StyledLayout>
      <div className={classes.buttonsContainer}>
        <Button variant="contained" className={classes.removeButton} onClick={() => setOpenRemoveDialog(true)}>
          <Icon>delete</Icon>
        </Button>
        <Button variant="contained" color="primary" onClick={() => navigate(`/blacklist/${id}/edit`)}>
          <Icon>edit</Icon>
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            { isLoading && (
              <div className={classes.loaderContainer}>
                <CircularProgress />
              </div>
            )}

            { blacklistData._id && (
              <Fragment>
                <ListItem
                  key={blacklistData._id}
                  details
                  {...blacklistData}
                />
                <Typography variant="body2" style={{ marginBottom: '8px' }}>
                  Telefon: { blacklistData.phone }
                </Typography>
                <Typography variant="body2" style={{ marginBottom: '8px' }}>
                  Skąd napisała: { blacklistData.source }
                </Typography>
                <Typography variant="body1">
                  {renderNotes()}
                </Typography>
                <CardActions sx={{
                  justifyContent: 'flex-end',
                  textAlign: 'right',
                  ['& h6']: { opacity: 0.8 }
                }}>
                  <Typography variant="subtitle2">
                    Dodano: {createdAtDate}
                    { createdAtDate !== updatedAtDate && (
                        <>
                          <br/>
                          Edycja: {updatedAtDate}
                        </>
                    )}
                  </Typography>
                </CardActions>
              </Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openRemoveDialog}
        onClose={() => setOpenRemoveDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Czy chcesz usunąć osobę z czarnej listy?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenRemoveDialog(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={() => handleDelete()} color="primary" autoFocus>
            Usuń
          </Button>
        </DialogActions>
      </Dialog>
    </StyledLayout>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.blacklists.isLoading, 
  blacklistData: state.blacklists.blacklist,
})

const mapDispatchToProps = {
  deleteBlacklist,
  findOneBlacklist,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(DetailsPage));
