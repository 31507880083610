import * as React from 'react';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import store from './redux';
import Main from './Main';
import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    min-height: 100vh;
  }
`;

const App = () => (
  <Provider store={store}>
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <div className="App">
        <GlobalStyle />
        <Main />
      </div>
    </SnackbarProvider>
  </Provider>
);

export default App;
