import React from 'react';
import { CardContent, Typography, Icon } from '@mui/material';
import { ListItemContainer, ListItemRow, ListItemContent, ListItemActions, ListItemAction, Place, CameraIcon } from '../../ListItem/ListItem.styled';
import { ClientData } from '../../../redux/modules/clients/clients.interfaces';
import {setRewindId} from "../../../redux/modules/base";
import {useDispatch} from "react-redux";

interface Props {
  details?: boolean,
  hidePhoneControls?: boolean,
  onClick?: () => void;
}

const ListItem: React.FunctionComponent<Partial<ClientData> & Props> = ({ 
  firstName, lastName, phone, notes, fromPhoto, _id, hidePhoneControls, onClick, details 
}) => {
  const dispatch = useDispatch();

  return (
    <ListItemContainer details={details} onClick={onClick ? onClick : () => {}}>
      <span id={`${phone}-anchor`} style={{ position: 'absolute', top: '-150px'}}></span>
      <CardContent onClick={() => dispatch(setRewindId({ rewindId: phone as string }))}>
        <ListItemRow>
          <ListItemContent>
            <Place variant="subtitle1">
              <CameraIcon>{ notes && notes.length > 0 && '📝' }</CameraIcon>
              <CameraIcon>{ fromPhoto && '📷' }</CameraIcon>
            </Place>
            <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
            <Typography variant="subtitle2">{phone}</Typography>
          </ListItemContent>
          { !hidePhoneControls && (
            <ListItemActions>
              <ListItemAction href={`tel:${phone}`} onClick={(e) => e.stopPropagation()}>
                <Icon>phone</Icon>
              </ListItemAction>
              <ListItemAction href={`sms:${phone}`} onClick={(e) => e.stopPropagation()}>
                <Icon>message</Icon>
              </ListItemAction>
            </ListItemActions>
          )}
        </ListItemRow>
      </CardContent>
    </ListItemContainer>
  )
}

export default ListItem;
