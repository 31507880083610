import axios, { AxiosResponse } from "axios";
import { setAll, setOne, update, remove, setIsLoading, addNewReserveList } from ".";
import { RootState } from "../..";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import {
  CreateReserveListFormData,
  ReserveListData,
  ReserveListDataDTO,
  UpdateReserveListDTO
} from "./reserveList.interfaces";

export const findAllReserveList = ():ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  const response: AxiosResponse<ReserveListDataDTO> = await axios.get(`${process.env.REACT_APP_API_URL}/makeup/`, {
    params: {
      reserveList: true,
    }
  });

  dispatch(setAll(response.data));
  dispatch(setIsLoading({ isLoading: false }));
}

export const findOneReserveList = (id: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async (dispatch, getState) => {
  const { reserveList } = getState();
  const reserveListEntry = reserveList.reserveListData.find(curMakeup => curMakeup._id === id);

  if (reserveListEntry) {
    dispatch(setOne(reserveListEntry));
    return;
  }

  const response: AxiosResponse<ReserveListData> = await axios.get(`${process.env.REACT_APP_API_URL}/makeup/${id}`);

  dispatch(setOne(response.data));
}

export const createReserveList = (data: CreateReserveListFormData):ThunkAction<Promise<ReserveListData>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    const response = await axios.post<ReserveListData>(`${process.env.REACT_APP_API_URL}/makeup`, data);
    const reserveList = response.data;
    dispatch(addNewReserveList(reserveList));
    return reserveList;
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export const updateReserveList = (id: string, data: UpdateReserveListDTO):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    const response: AxiosResponse<ReserveListData> = await axios.put(`${process.env.REACT_APP_API_URL}/makeup/${id}`, data);
    dispatch(update(response.data));
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}

export const deleteReserveList = (id: string):ThunkAction<Promise<void>, RootState, null, AnyAction> => async dispatch => {
  dispatch(setIsLoading({ isLoading: true }));
  try {
    await axios.delete(`${process.env.REACT_APP_API_URL}/makeup/${id}`);
    dispatch(remove(id));
  } catch (e) {
    throw e;
  } finally {
    dispatch(setIsLoading({ isLoading: false }));
  }
}
