import React, {Fragment, useEffect} from 'react'
import {styled} from '@mui/material/styles';
import {navigate, RouteComponentProps} from '@reach/router';
import {
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Icon,
  Paper,
  Typography,
} from '@mui/material';
import Layout from '../../components/Layout/Layout';
import ListItem from '../../components/ReserveList/ListItem';
import {withSnackbar, WithSnackbarProps} from 'notistack';
import {updateMakeup,} from '../../redux/modules/makeups/makeups.thunks';
import {connect, useDispatch} from 'react-redux';
import {RootState} from '../../redux';
import {MakeupType} from '../../redux/modules/makeups/makeups.interfaces';
import dayjs from "../../libs/dayjs";
import {ReserveListData} from "../../redux/modules/reserveList/reserveList.interfaces";
import {deleteReserveList, findOneReserveList} from "../../redux/modules/reserveList/reserveList.thunks";

const PREFIX = 'Details';

const classes = {
  paper: `${PREFIX}-paper`,
  loaderContainer: `${PREFIX}-loaderContainer`,
  buttonsContainer: `${PREFIX}-buttonsContainer`,
  button: `${PREFIX}-button`,
  removeButton: `${PREFIX}-removeButton`,
  restoreButton: `${PREFIX}-restoreButton`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
  },

  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  },

  [`& .${classes.buttonsContainer}`]: {
    marginLeft: 'auto',
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  [`& .${classes.button}`]: {
    marginRight: 16,
  },

  [`& .${classes.removeButton}`]: {
    marginRight: '16px',
    backgroundColor: theme.palette.error.light,
  },

  [`& .${classes.restoreButton}`]: {
    marginRight: '16px',
    backgroundColor: theme.palette.divider,
  }
}));

interface Props {
  id: string,
}

interface DetailsPageReduxProps {
  isLoading: boolean,
  reserveListEntry: ReserveListData,
}

const DetailsPage: React.FunctionComponent<RouteComponentProps & Props & WithSnackbarProps & DetailsPageReduxProps> = ({
  id, enqueueSnackbar, isLoading, reserveListEntry, location
}) => {
  const dispatch = useDispatch();
  const [openRemoveDialog, setOpenRemoveDialog] = React.useState(false);
  const [openTransformDialog, setOpenTransformDialog] = React.useState(false);
  const createdAtDate = dayjs(reserveListEntry.createdAt).format('DD.MM.YY HH:mm');
  const updatedAtDate = dayjs(reserveListEntry.updatedAt).format('DD.MM.YY HH:mm');

  const handleDelete = async () => {
    try {
      await dispatch(deleteReserveList(id));
      enqueueSnackbar('Wpis na listę rezerwową usunięty',{
        variant: 'success',
      });
      navigate('/reserve-list');
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  }

  const handleReserveListTransform = async () => {
    try {
      await dispatch(updateMakeup(id, {
        ...reserveListEntry,
        peopleCount: reserveListEntry.peopleCount.toString(),
        type: MakeupType.MAKEUP,
      } as any));

      enqueueSnackbar("Wpis z listy rezerwowej został przepisany",{
        variant: 'success',
      });
      navigate(`/makeup/${id}/edit`);
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  }

  useEffect(() => {
    dispatch(findOneReserveList(id));
  }, [id]);

  const renderMakeupNotes = () => {
    if (!reserveListEntry.notes) {
      return null;
    }
    return reserveListEntry.notes.split('\n').map((text, index) => (
        <p key={index}>{text}</p>
    ))
  }
  return (
    <StyledLayout>
      <div className={classes.buttonsContainer}>
        <Button variant="contained" className={classes.removeButton} onClick={() => setOpenRemoveDialog(true)}>
          <Icon>delete</Icon>
        </Button>
        <Button variant="contained" color="primary" className={classes.button} onClick={() => setOpenTransformDialog(true)}>
          <Icon>event_available</Icon>
        </Button>
        <Button variant="contained" color="primary" onClick={() => navigate(`/reserve-list/${id}/edit`)}>
          <Icon>edit</Icon>
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            { isLoading && (
              <div className={classes.loaderContainer}>
                <CircularProgress />
              </div>
            )}

            { reserveListEntry._id && (
              <Fragment>
                <ListItem
                  key={reserveListEntry._id}
                  details
                  {...reserveListEntry}
                />
                {!["FB", "Instagram"].includes(reserveListEntry.source) && (
                  <Typography variant="body2" style={{ marginBottom: '8px' }}>
                    Telefon: { reserveListEntry.phone }
                  </Typography>
                )}

                <Typography variant="body2" style={{ marginBottom: '8px' }}>
                  Skąd napisała: { reserveListEntry.source }
                </Typography>

                {reserveListEntry.instagram && (
                  <Typography variant="body2" style={{ marginBottom: '8px' }}>
                    Nick na instagramie: { reserveListEntry.instagram } <a href={`https://instagram.com/${reserveListEntry.instagram}`} target="_blank" rel="noopener noreferrer">Przejdź</a>
                  </Typography>
                )}

                <Typography variant="body1">
                  {renderMakeupNotes()}
                </Typography>
                <CardActions sx={{
                  justifyContent: 'flex-end',
                  textAlign: 'right',
                  ['& h6']: { opacity: 0.8 }
                }}>
                  <Typography variant="subtitle2">
                    Dodano: {createdAtDate}
                    { createdAtDate !== updatedAtDate && (
                      <>
                        <br/>
                        Edycja: {updatedAtDate}
                      </>
                    )}
                  </Typography>
                </CardActions>
              </Fragment>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={openRemoveDialog}
        onClose={() => setOpenRemoveDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Czy chcesz usunąć wpis na liste rezerwacji?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenRemoveDialog(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={() => handleDelete()} color="primary" autoFocus>
            Usuń
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
          open={openTransformDialog}
          onClose={() => setOpenTransformDialog(false)}
      >
        <DialogTitle id="alert-dialog-title">{"Czy chcesz przenieść wpis z listy rezerwowej?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenTransformDialog(false)} color="primary">
            Anuluj
          </Button>
          <Button onClick={() => handleReserveListTransform()} color="primary" autoFocus>
            Potwierdź
          </Button>
        </DialogActions>
      </Dialog>
    </StyledLayout>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.reserveList.isLoading,
  reserveListEntry: state.reserveList.reserveListEntry,
})

export default connect(
  mapStateToProps,
)(withSnackbar(DetailsPage));
