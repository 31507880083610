import React from 'react'
import { styled } from '@mui/material/styles';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import { RouteComponentProps, navigate } from '@reach/router';
import { CircularProgress } from '@mui/material';
import Layout from '../components/Layout/Layout';
import dayjs from '../libs/dayjs';
import Form from '../components/Form/Form';
import { RootState } from '../redux';
import { updateMakeup, findOneMakeup, UpdateMakeup, FindOneMakeup } from '../redux/modules/makeups/makeups.thunks';
import { connect } from 'react-redux';
import {MakeupData, MakeupType, UpdateMakeupDTO} from '../redux/modules/makeups/makeups.interfaces';
import PhotoForm from "../components/Form/PhotoForm";

const PREFIX = 'Update';

const classes = {
  loaderContainer: `${PREFIX}-loaderContainer`
};

const StyledLayout = styled(Layout)((
  {
    theme
  }
) => ({
  [`& .${classes.loaderContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
  }
}));

interface Props {
  id: string,
}

interface UpdatePageReduxProps {
  isLoading: boolean,
  makeupData: MakeupData,
  updateMakeup: UpdateMakeup,
  findOneMakeup: FindOneMakeup,
}

const UpdatePage: React.FunctionComponent<RouteComponentProps & Props & UpdatePageReduxProps & WithSnackbarProps> = ({
  id, enqueueSnackbar, isLoading, makeupData, updateMakeup, findOneMakeup, location
}) => {


  const submitForm = async (values: UpdateMakeupDTO) => {
    try {
      await updateMakeup(id, {
        ...values,
      });
      enqueueSnackbar((() => {
        switch (values.type) {
          case MakeupType.TRIAL_WEDDING:
            return 'Makijaż próbny ślubny został zmodyfikowany';
          case MakeupType.GROOM:
            return 'Poprawka pana młodego została zmodyfikowany';
          case MakeupType.WEDDING:
            return 'Makijaż ślubny został zmodyfikowany';
          case MakeupType.MAKEUP:
            return 'Makijaż został zmodyfikowany';
          case MakeupType.RESERVATION:
            return 'Rezerwacja została zmodyfikowana';
          case MakeupType.LESSON:
            return 'Lekcja makijażu została zmodyfikowana';
          case MakeupType.BREAK:
            return 'Przerwa została zmodyfikowana';
          case MakeupType.MODEL:
            return 'Modelka została zmodyfikowana';
        }
      })(),{
        variant: 'success',
      });
      if (location && location.state && (location.state as any).fromArchive) {
        navigate('/archive')
        return;
      }
      navigate('/', {
        state: {
          makeup: {
            _id: id,
          },
        },
      });
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  };

  React.useEffect(() => {
    findOneMakeup(id);
  }, [id, findOneMakeup]);

  return (
    <StyledLayout>
      { isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}

      { makeupData._id && (
        <>
          <Form
            isLoading={isLoading}
            onFormSubmit={submitForm}
            onFormCancel={() => navigate(`/makeup/${id}`)}
            formSubmitButtonText="Zaktualizuj"
            {...makeupData}
            peopleCount={makeupData.peopleCount.toString()}
            date={dayjs(makeupData.date).format('YYYY-MM-DD')}
            time={makeupData.time}

          />

          <PhotoForm />
        </>
      )}

    </StyledLayout>
  );
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.makeups.isLoading,
  makeupData: state.makeups.makeup,
})

const mapDispatchToProps = {
  updateMakeup,
  findOneMakeup,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(UpdatePage));
