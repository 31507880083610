import React from "react";
import { styled } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  Paper,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Icon,
  InputAdornment,
  List,
  Checkbox,
  FormControlLabel,
  FormHelperText
} from "@mui/material";
import {
  CreateMakeupFormData,
  MakeupType
} from "../../redux/modules/makeups/makeups.interfaces";
import { getClients } from "../../redux/modules/clients";
import { findSearchClients } from "../../redux/modules/clients/clients.thunks";
import ListItem from "../ClientsDatabase/ListItem/ListItem";
import { GroupedClientData } from "../../redux/modules/clients/clients.interfaces";
import { debounce } from "lodash";
import Axios from "axios";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import PhoneInput from "../Form/PhoneInput";
import { CreateReserveListFormData } from "../../redux/modules/reserveList/reserveList.interfaces";

const PREFIX = "Form";

const classes = {
  paper: `${PREFIX}-paper`,
  form: `${PREFIX}-form`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  loaderContainer: `${PREFIX}-loaderContainer`,
  formControl: `${PREFIX}-formControl`
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2)
  },

  [`& .${classes.form}`]: {
    display: "flex",
    flexDirection: "column"
  },

  [`& .${classes.buttonContainer}`]: {
    display: "flex",
    justifyContent: "flex-end"
  },

  [`& .${classes.loaderContainer}`]: {
    display: "flex",
    justifyContent: "center",
    padding: "16px"
  },

  [`& .${classes.formControl}`]: {
    marginTop: "16px",
    marginBottom: "8px"
  }
}));

interface CheckDateDto {
  _id?: string;
  date: string;
  time: string;
}

interface Props {
  isLoading: boolean;
  onFormCancel: () => any;
  onFormSubmit: (values: CreateReserveListFormData) => Promise<any>;
  formSubmitButtonText: string;
}

const Form: React.FunctionComponent<CreateReserveListFormData & Props> = ({
  isLoading,
  onFormCancel,
  onFormSubmit,
  formSubmitButtonText,
  ...initialValues
}) => {
  const dispatch = useDispatch();
  const { searchClients: clients } = useSelector(getClients);

  const [clientSelectDialogOpen, setClientSelectDialogOpen] = React.useState(
    false
  );
  const [isBlacklistEntry, setIsBlacklistEntry] = React.useState(false);
  const [query, setQuery] = React.useState("");

  const [values, setValues] = React.useState<CreateReserveListFormData>(
    initialValues
  );

  const filteredClients = React.useMemo<GroupedClientData[]>(() => {
    if (!query) {
      return [
        ...clients.filter(client => client.pinned),
        ...clients.filter(client => !client.pinned)
      ].filter(Boolean);
    }

    return clients.filter(
      client =>
        `${client.firstName.toLowerCase()} ${client.lastName.toLowerCase()}`.includes(
          query.toLowerCase()
        ) || client.phone.replace(/ /g, "").includes(query)
    );
  }, [query, clients]);

  const checkBlacklist = React.useMemo(
    () =>
      debounce(async (phone: string) => {
        const response = await Axios.post<boolean>(
          `${process.env.REACT_APP_API_URL}/blacklist/check`,
          {
            phone
          }
        );

        setIsBlacklistEntry(response.data);
      }, 300),
    []
  );

  React.useEffect(() => {
    if (!clients.length) {
      dispatch(findSearchClients());
    }
  }, [clients, dispatch]);

  React.useEffect(() => {
    checkBlacklist(values.phone ?? '');
  }, [checkBlacklist, values.phone]);

  const handleClickOpen = () => {
    setClientSelectDialogOpen(true);
  };

  const handleClose = () => {
    setClientSelectDialogOpen(false);
  };

  const handleClientSelect = (client: GroupedClientData) => {
    setValues({
      ...values,
      firstName: client.firstName,
      lastName: client.lastName,
      phone: client.phone,
      instagram: client.instagram,
      notes: client.notes
    });
    setClientSelectDialogOpen(false);
  };

  const handleChange = (name: keyof CreateReserveListFormData) => (
    event: React.ChangeEvent<HTMLInputElement | { value: unknown }>
  ) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleSelectChange = (name: keyof CreateReserveListFormData) => (
    event: SelectChangeEvent
  ) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleCheckboxChange = (name: keyof CreateReserveListFormData) => (
    event: React.ChangeEvent<HTMLInputElement | { checked: unknown }>
  ) => {
    setValues({ ...values, [name]: event.target.checked });
  };

  return (
    <Root>
      <Grid container spacing={3}>
        <Grid container item xs={12} justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Wyszukaj dane klienta
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <form noValidate autoComplete="off" className={classes.form}>
              {values.source === "FB" && (
                <>
                  <TextField
                    id="firstName"
                    label="Imię"
                    value={values.firstName}
                    onChange={handleChange("firstName")}
                    margin="normal"
                  />

                  <TextField
                    id="lastName"
                    label="Nazwisko"
                    value={values.lastName}
                    onChange={handleChange("lastName")}
                    margin="normal"
                  />
                </>
              )}

              <FormControl className={classes.formControl}>
                <InputLabel id="source-label">Skąd napisała?</InputLabel>
                <Select
                  label="Skąd napisała?"
                  labelId="source-label"
                  native
                  value={values.source}
                  onChange={handleSelectChange("source")}
                  inputProps={{
                    name: "source",
                    id: "source"
                  }}
                >
                  <option value="" />
                  <option value="Olx">Olx</option>
                  <option value="Olx/SMS">Olx/SMS</option>
                  <option value="Foto">Foto</option>
                  <option value="Instagram">Instagram</option>
                  <option value="FB">FB</option>
                  <option value="SMS">SMS</option>
                </Select>
              </FormControl>

              {values.source === "Instagram" && (
                <TextField
                  id="instagram"
                  label="Nick na instagramie"
                  value={values.instagram}
                  onChange={handleChange("instagram")}
                  margin="normal"
                />
              )}

              {values.source !== "Instagram" && values.source !== "FB" && (
                <FormControl
                  className={classes.formControl}
                  error={isBlacklistEntry}
                >
                  <TextField
                    label="Telefon kontaktowy"
                    id="phone"
                    error={isBlacklistEntry}
                    value={values.phone}
                    onChange={handleChange("phone")}
                    InputProps={{
                      inputComponent: PhoneInput as any
                    }}
                  />
                  <FormHelperText error={isBlacklistEntry}>
                    {isBlacklistEntry ? "Numer z czarnej list" : " "}
                  </FormHelperText>
                </FormControl>
              )}

              <div>
                <TextField
                  id="date"
                  label="Data makijażu"
                  type="date"
                  value={values.date}
                  onChange={handleChange("date")}
                  margin="normal"
                  style={{ marginRight: "8px" }}
                />
              </div>

              <TextField
                id="standard-multiline-flexible"
                label="Notatki"
                multiline
                value={values.notes}
                onChange={handleChange("notes")}
                margin="normal"
              />
            </form>
            {isLoading && (
              <div className={classes.loaderContainer}>
                <CircularProgress />
              </div>
            )}
            <div className={classes.buttonContainer}>
              <Button onClick={onFormCancel}>Anuluj</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onFormSubmit(values)}
              >
                {formSubmitButtonText}
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        open={clientSelectDialogOpen}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: "100%",
            margin: 16
          }
        }}
      >
        <DialogTitle id="form-dialog-title">Wyszukaj dane klienta</DialogTitle>
        <DialogContent>
          <div>
            <TextField
              value={query}
              autoFocus
              margin="dense"
              id="name"
              label="Wyszukaj"
              type="text"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>search</Icon>
                  </InputAdornment>
                )
              }}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
          <List subheader={<li />} style={{ width: "100%" }}>
            {filteredClients.map(clientEl => {
              if (
                clientEl.phone === "+48" ||
                clientEl.phone === "+48            "
              ) {
                return <></>;
              }

              return (
                <ListItem
                  key={clientEl.phone}
                  hidePhoneControls
                  {...clientEl}
                  onClick={() => handleClientSelect(clientEl)}
                />
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Anuluj
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default Form;
